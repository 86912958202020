import { CSSProperties, ReactNode, useEffect, useState } from 'react';

import { DynamicForm } from '@/features/dynamic-form';
import { Responses } from '@/features/form';
import { I18nProvider } from '@/features/i18n';
import { PiiPage } from '@/features/pii';
import { QuestionnaireResult, ResultPage } from '@/features/result';

import { AppStage, DrawerContent } from '../constants';
import { appContext } from '../contexts';
import { DynamicAppInitializer, LocationStub } from '../types';

import { Layout } from './Layout';
import { StartPage } from './StartPage';
import { ProhibitedPage } from './ProhibitedPage';

export function InitializedApp({
  accessCode,
  appInitializer,
}: {
  accessCode: string;
  appInitializer: DynamicAppInitializer;
}) {
  const [appStage, setAppStage] = useState<AppStage>(AppStage.START);
  const [drawerContent, setDrawerContent] = useState<DrawerContent | null>(null);
  const [finalResponses, setFinalResponses] = useState<Responses | null>(null);
  const [location, setLocation] = useState<LocationStub | null>(null);
  const [questionnairePrn, setQuestionnairePrn] = useState<string | null>(null);
  const [responsePreloads, setResponsePreloads] = useState<Responses>({});
  const [result, setResult] = useState<QuestionnaireResult | null>(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    scrollTo(0, 0);
  }, [appStage]);

  let page: ReactNode;

  switch (appStage) {
    case AppStage.START: {
      page = <StartPage />;
      break;
    }
    case AppStage.PII: {
      page = <PiiPage
        accessCode={accessCode}
        setLocation={setLocation}
        setResponsePreloads={setResponsePreloads}
        setQuestionnairePrn={setQuestionnairePrn}
      />;

      break;
    }
    case AppStage.PROHIBITED: {
      page = <ProhibitedPage />;

      break;
    }
    case AppStage.QUESTIONNAIRE: {
      if (location === null) {
        throw new Error();  // TODO
      }

      page = <DynamicForm
        location={location}
        responsePreloads={responsePreloads}
        setFinalResponses={setFinalResponses}
        template={appInitializer.template}
      />;

      break;
    }
    case AppStage.RESULT: {
      if (result === null) {
        throw new Error();  // TODO
      }

      page = <ResultPage result={result} />;

      break;
    }
    default: {
      throw new Error();  // TODO
    }
  }

  return (
    <I18nProvider>
      <appContext.Provider value={{
        appInitializer,
        finalResponses,
        questionnairePrn,
        setAppStage,
        setDrawerContent,
        setResult,
        setSubmitting,
        submitting,
      }}>
        <div style={{
          '--primary-color': appInitializer.branding.primaryColor,
        } as CSSProperties}>
          <Layout drawerContent={drawerContent}>
            {page}
          </Layout>
        </div>
      </appContext.Provider>
    </I18nProvider>
  );
}

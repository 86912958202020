import { Language, useI18nContext } from '@/features/i18n';

import { Card } from './Card';
import { Page } from './Page';

export function ProhibitedPage() {
  const { language } = useI18nContext();

  return (
    <Page title={{
      [Language.ENGLISH]: 'You May Not Proceed',
      [Language.SPANISH]: 'No Es Posible Proceder',
    }[language]}>
      <Card>
        {{
          [Language.ENGLISH]: `
            We recently received a questionnaire from you. Please contact
            Proxima Support at 
          `,
          [Language.SPANISH]: `
            Recientemente recibimos un cuestionario suyo. Póngase en contacto
            con el servicio de asistencia de Proxima en 
          `,
        }[language]}
        <a className="text-primary hover:underline" href="mailto:support@proximawork.com">support@proximawork.com</a>
        {{
          [Language.ENGLISH]: ' to continue your Respirator Medical Evaluation.',
          [Language.SPANISH]: ' para continuar con la evaluación médica de su respirador.',
        }[language]}
      </Card>
    </Page>
  );
}

import { useState } from 'react';

import { useFormContext } from '../contexts';
import { HeightWeightFieldUiProps } from '../types';

import { FieldWrapper } from './FieldWrapper';
import { TextInput } from './TextInput';

export function HeightWeightField({
  height,
  questionId,
  weight,
}: HeightWeightFieldUiProps) {
  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
      {/* TODO | Get max/min from props or question def somehow */}
      <HeightField max={107} min={36} questionId={questionId.height} {...height} />
      <WeightField max={999} min={10} questionId={questionId.weight} {...weight} />
    </div>
  );
}

function HeightField({
  max,
  min,
  prompt,
  questionId,
  units,
}: HeightWeightFieldUiProps['height'] & {
  questionId: string;
}) {
  const { getError, setResponse } = useFormContext();

  const [enteredFeet, setEnteredFeet] = useState('');
  const [enteredInches, setEnteredInches] = useState('');

  const error = getError(questionId);

  return (
    <FieldWrapper className="col-span-2" error={error} prompt={prompt}>
      <div className="grid grid-cols-2 gap-x-6">
        <TextInput
          hasError={error !== undefined}
          inputMode="numeric"
          maxLength={
            max !== undefined
              ? Math.floor(max / 12).toString().length
              : undefined
          }
          name={`${questionId}_feet`}
          onChange={(feet) => {
            feet = feet.replace(/[^0-9]/g, '');
            setEnteredFeet(feet);

            if (feet.trim() === '' && enteredInches.trim() === '') {
              setResponse(questionId, null);
            } else if (/^[1-9][0-9]*$/.test(feet)
                && /^(0|1|2|3|4|5|6|7|8|9|10|11)$/.test(enteredInches)) {
              const height = (parseInt(feet) * 12) + parseInt(enteredInches);

              if ((max === undefined || height <= max)
                  && (min === undefined || height >= min)) {
                setResponse(questionId, height);
              } else {
                setResponse(questionId, NaN);
              }
            } else {
              setResponse(questionId, NaN);
            }
          }}
          rightAddOn={units.feet}
          value={enteredFeet}
        />
        <TextInput
          hasError={error !== undefined}
          inputMode="numeric"
          maxLength={2}
          name={`${questionId}_inches`}
          onChange={(inches) => {
            inches = inches.replace(/[^0-9]/g, '');
            setEnteredInches(inches);

            if (inches.trim() === '' && enteredFeet.trim() === '') {
              setResponse(questionId, null);
            } else if (/^[1-9][0-9]*$/.test(enteredFeet)
                && /^(0|1|2|3|4|5|6|7|8|9|10|11)$/.test(inches)) {
              const height = (parseInt(enteredFeet) * 12) + parseInt(inches);

              if ((max === undefined || height <= max)
                  && (min === undefined || height >= min)) {
                setResponse(questionId, height);
              } else {
                setResponse(questionId, NaN);
              }
            } else {
              setResponse(questionId, NaN);
            }
          }}
          rightAddOn={units.inches}
          value={enteredInches}
        />
      </div>
    </FieldWrapper>
  );
}

function WeightField({
  max,
  min,
  prompt,
  questionId,
  units,
}: HeightWeightFieldUiProps['weight'] & {
  questionId: string;
}) {
  const { getError, setResponse } = useFormContext();

  const [enteredPounds, setEnteredPounds] = useState('');

  const error = getError(questionId);

  return (
    <FieldWrapper error={error} prompt={prompt}>
      <TextInput
        hasError={error !== undefined}
        inputMode="numeric"
        maxLength={max?.toString().length}
        name={questionId}
        onChange={(pounds) => {
          pounds = pounds.replace(/[^0-9]/g, '');
          setEnteredPounds(pounds);
  
          if (pounds.trim() === '') {
            setResponse(questionId, null);
          } else if (/^[1-9][0-9]*$/.test(pounds)) {
            const weight = parseInt(pounds);

            if ((max === undefined || weight <= max)
                && (min === undefined || weight >= min)) {
              setResponse(questionId, weight);
            } else {
              setResponse(questionId, NaN);
            }
          } else {
            setResponse(questionId, NaN);
          }
        }}
        rightAddOn={units}
        value={enteredPounds}
      />
    </FieldWrapper>
  );
}

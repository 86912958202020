import {
  FormErrors,
  FormPageUiProps,
  Responses,
  isQuestionFormFieldUiProps,
} from '@/features/form';
import { DataType } from '@/types/data';
import { isJsonArray } from '@/utils/json';

import { DynamicFormErrorMessages, DynamicFormQuestions } from '../types';

export function validatePage({
  errorMessages,
  pageProps,
  questions,
  responses,
}: {
  errorMessages: DynamicFormErrorMessages;
  pageProps: FormPageUiProps;
  questions: DynamicFormQuestions;
  responses: Responses;
}): FormErrors {
  const errors: FormErrors = {};

  function errorMessage(errorType: string): string {
    const message = errorMessages[errorType];

    if (message === undefined) {
      throw new Error();  // TODO
    }

    return message;
  }

  const REQUIRED = 'required';
  const INVALID = 'invalid';

  for (const moduleProps of pageProps.modules) {
    for (const fieldProps of moduleProps.fields) {
      let questionIds: string[];

      if (isQuestionFormFieldUiProps(fieldProps)) {
        if (typeof fieldProps.questionId === 'string') {
          questionIds = [fieldProps.questionId];
        } else {
          questionIds = Object.values(fieldProps.questionId);
        }

        for (const questionId of questionIds) {
          const question = questions[questionId];
          const response = responses[questionId];

          if (question === undefined) {
            throw new Error();  // TODO | Template error
          }

          const include = question.include ?? true;
          const require = include ? (question.require ?? true) : false;

          if (response === undefined) {
            if (include) {
              throw new Error();  // TODO
            }

            continue;
          }
      
          if (response === null) {
            if (require) {
              errors[questionId] = errorMessage(REQUIRED);
            }

            continue;
          }

          switch (question.type) {
            case DataType.ARRAY: {
              if (!isJsonArray(response)) {
                errors[questionId] = errorMessage(INVALID);
                continue;
              }

              if (question.maxLength !== undefined && response.length > question.maxLength) {
                errors[questionId] = errorMessage(INVALID);
                continue;
              }

              if (question.minLength !== undefined && response.length < question.minLength) {
                if (response.length === 0) {
                  errors[questionId] = errorMessage(REQUIRED);
                } else {
                  errors[questionId] = errorMessage(INVALID);
                }

                continue;
              }

              break;
            } case DataType.NUMBER: {
              if (typeof response !== 'number' || isNaN(response)) {
                errors[questionId] = errorMessage(INVALID);
                continue;
              }

              if (question.max !== undefined && response > question.max) {
                errors[questionId] = errorMessage(INVALID);
              }

              if (question.min !== undefined && response < question.min) {
                errors[questionId] = errorMessage(INVALID);
              }

              break;
            } case DataType.STRING: {
              if (typeof response !== 'string') {
                errors[questionId] = errorMessage(INVALID);
                continue;
              }

              if (require && response.length === 0) {
                errors[questionId] = errorMessage(REQUIRED);
                continue;
              }

              if (question.maxLength !== undefined && response.length > question.maxLength) {
                errors[questionId] = errorMessage(INVALID);
                continue;
              }

              if (question.minLength !== undefined && response.length < question.minLength) {
                errors[questionId] = errorMessage(INVALID);
                continue;
              }
  
              break;
            }
          }

          if (question.errors !== undefined) {
            for (const [errorType, isError] of Object.entries(question.errors)) {
              if (isError) {
                errors[questionId] = errorMessage(errorType);
                break;
              }
            }
          }
        }
      }
    }
  }

  return errors;
}

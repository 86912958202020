import { ReactNode } from 'react';

import { Title } from '../Title';

import { PageBlurb } from './PageBlurb';

export function Page({
  blurb,
  children,
  title,
}: {
  blurb?: ReactNode;
  children: ReactNode;
  title?: string;
}) {
  return (
    <>
      {title !== undefined && (
        <Title title={title} />
      )}
      {blurb !== undefined && (
        <PageBlurb>
          {blurb}
        </PageBlurb>
      )}
      {children}
    </>
  );
}

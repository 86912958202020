import { createContext, useContext } from 'react';

import { Response } from './types';

export type FormContextValue = {
  getError: (id: string) => string | undefined;
  getResponse: (id: string) => Response;
  goNext: () => void;
  setResponse: (id: string, value: Response) => void;
};

export const formContext = createContext<FormContextValue>({} as FormContextValue);

export function useFormContext() {
  return useContext(formContext);
}

import { useEffect, useState } from 'react';

import { formContext } from '../contexts';
import { FormProps } from '../types';

import { FormPage } from './FormPage';

export function Form({
  errors,
  onComplete,
  onGoNext,
  onSetResponse,
  pages,
  responses,
}: FormProps) {
  const [currentPageId, setCurrentPageId] = useState<string>(() => {
    const firstPage = pages[0];

    if (firstPage === undefined) {
      throw new Error();  // TODO | No pages to show
    }

    return firstPage.id;
  });

  useEffect(() => {
    scrollTo(0, 0);
  }, [currentPageId]);

  const pageProps = pages.find((page) => page.id === currentPageId);

  if (pageProps === undefined) {
    throw new Error();  // TODO | Page not found
  }

  return (
    <formContext.Provider value={{
      getError(id) {
        return errors[id];
      },
      getResponse(id) {
        const response = responses[id];

        if (response === undefined) {
          throw new Error();  // TODO
        }

        return response;
      },
      goNext() {
        const canGoNext = onGoNext(currentPageId);

        if (canGoNext) {
          const finalPageProps = pages.at(-1);

          if (finalPageProps === undefined) {
            throw new Error();  // TODO
          }

          if (currentPageId === finalPageProps.id) {
            // We were on the final page
            onComplete();
          } else {
            // We were not on the final page; go to the next page
            const currentPageIndex = pages.indexOf(pageProps);

            if (currentPageIndex === -1) {
              throw new Error();  // TODO
            }

            const nextPageProps = pages[currentPageIndex + 1];

            if (nextPageProps === undefined) {
              throw new Error();  // TODO
            }

            setCurrentPageId(nextPageProps.id);
          }
        } else {
          setTimeout(() => {
            const errors = document.getElementsByClassName('has-error');
    
            if (errors.length > 0) {
              const firstError = errors[0] as HTMLElement;

              scrollTo({
                behavior: 'smooth',
                top: firstError.offsetTop - 74,
              });
            }
          }, 50);
        }
      },
      setResponse(key, value) {
        onSetResponse(key, value);
      },
    }}>
      <FormPage {...pageProps} />
    </formContext.Provider>
  );
}

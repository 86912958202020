export function Title({
  title,
}: {
  title: string;
}) {
  return (
    <h1 className="font-display text-3xl mb-8 px-4 sm:px-0">
      {title}
    </h1>
  )
}

import { Button } from '@/components/Button';

import { FormButtonUiProps } from '../types';
import { useFormContext } from '../contexts';

export function NextButton({
  label,
}: FormButtonUiProps) {
  const { goNext } = useFormContext();

  return (
    <div className="flex w-full justify-center">
      <Button onClick={goNext}>
        {label}
      </Button>
    </div>
  );
}

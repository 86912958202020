import { FormFieldUiPropsBase, FormPageUiProps, QuestionFormFieldUiProps } from './types';

export function getPageUiProps({
  allPageUiProps,
  id,
}: {
  allPageUiProps: FormPageUiProps[];
  id: string;
}): FormPageUiProps {
  const pageProps = allPageUiProps.find((page) => page.id === id);

  if (pageProps === undefined) {
    throw new Error();  // TODO | Page not found
  }

  return pageProps;
}

export function isQuestionFormFieldUiProps(
  props: FormFieldUiPropsBase,
): props is QuestionFormFieldUiProps {
  return (props as { questionId?: unknown; }).questionId !== undefined;
}

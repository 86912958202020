import * as Sentry from '@sentry/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

import { Button } from '@/components/Button';
import { Language, useI18nContext } from '@/features/i18n';
import { QuestionnaireResult } from '@/features/result';

import { AppStage } from '../constants';
import { useAppContext } from '../contexts';

import { SubmitError } from './SubmitError';
import { Title } from './Title';

export function Attestation() {
  const { finalResponses, questionnairePrn, setAppStage, setDrawerContent, setResult } = useAppContext();
  const { language } = useI18nContext();

  const [acceptRelease, setAcceptRelease] = useState(false);
  const [submitState, setSubmitState] = useState<'ERROR' | 'READY' | 'SUBMITTING'>('READY');

  function handleSubmit() {
    async function _handleSubmit() {
      if (acceptRelease) {
        if (finalResponses === null) {
          throw new Error();  // TODO
        }

        setSubmitState('SUBMITTING');

        try {
          const result = await fetch(
            `${import.meta.env.VITE_API_BASE_URL}/questionnaires/${questionnairePrn}/finalize-dynamic`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'POST',
              body: JSON.stringify({
                responses: finalResponses,
              }),
            },
          );

          if (result.status !== 200) {
            throw new Error();
          }
        
          const questionnaireResult = await result.json() as QuestionnaireResult;

          setDrawerContent(null);
          setResult(questionnaireResult);
          setAppStage(AppStage.RESULT);
        } catch (err) {
          Sentry.captureException(err);
          setSubmitState('ERROR');
        }
      }
    }

    void _handleSubmit();
  }

  return (
    <div className="p-8">
      <div className="mx-auto max-w-3xl sm:px-6 lg:px-8 flex flex-col gap-4">
        <div className="text-right">
          <button onClick={() => {
            if (submitState !== 'SUBMITTING') {
              setDrawerContent(null);
            }
          }} type="button" className="bg-white border-gray-400 border-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <Title title={{
          [Language.ENGLISH]: 'Release Conditions',
          [Language.SPANISH]: 'Condiciones de Liberación',
        }[language]} />
        <p className="font-semibold">
          {{
            [Language.ENGLISH]: `
              You must review and accept the following release conditions before
              submitting this questionnaire:
            `,
            [Language.SPANISH]: `
              Debe revisar y aceptar las siguientes condiciones de liberación
              antes de enviar este cuestionario:
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              The answers I have provided in this questionnaire are my own and
              are truthful and complete to the best of my knowledge.
            `,
            [Language.SPANISH]: `
              Las respuestas que he proporcionado en este cuestionario son mías
              y son veraces y completas según mi leal saber y entender.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              I hereby fully release the contents of this questionnaire, which I
              supplied willingly to Proxima WorkTech, LLC on behalf of my
              Employer.
            `,
            [Language.SPANISH]: `
              Por la presente libero totalmente el contenido de este
              cuestionario, el cual suministré voluntariamente a Proxima
              WorkTech, LLC en nombre de mi Empleador.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              I understand that the sole purpose of this questionnaire is to
              assess my health as it pertains to using a respirator and/or
              working around potential respirable hazards. This questionnaire
              and its review are not an attempt to treat or diagnose any health
              condition.
            `,
            [Language.SPANISH]: `
              Entiendo que el único propósito de este cuestionario es evaluar mi
              salud en lo que se refiere al uso de un respirador y/o al trabajo
              alrededor de peligros potenciales respirables. Este cuestionario y
              su revisión no son un intento de tratar o diagnosticar ninguna
              condición de salud.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              I understand that the information that I disclose to Proxima
              WorkTech and/or my employer, pursuant to this authorization, will
              no longer be subject to federal healthcare privacy regulations. I
              have the right to seek assurance from my employer that any further
              disclosures will be made in conformity with OSHA and other
              applicable state and federal regulations, and the medical
              confidentiality requirements of my employer. I understand my
              employer is required to keep records of my employment as per state
              and federal regulations. Proxima WorkTech, LLC will not disclose
              your information without your consent.
            `,
            [Language.SPANISH]: `
              Entiendo que la información que revele a Proxima WorkTech y/o a mi
              empleador, de conformidad con esta autorización, ya no estará
              sujeta a las regulaciones federales de privacidad de la atención
              médica. Tengo derecho a solicitar garantías a mi empleador de que
              cualquier divulgación posterior se realizará de conformidad con la
              OSHA y otras normativas estatales y federales aplicables, así como
              con los requisitos de confidencialidad médica de mi empleador.
              Entiendo que mi empleador está obligado a mantener registros de mi
              empleo de acuerdo con las regulaciones estatales y federales.
              Proxima WorkTech, LLC no divulgará su información sin su
              consentimiento.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              I understand Proxima WorkTech, LLC may receive financial
              compensation in exchange for reviewing the information I provide.
            `,
            [Language.SPANISH]: `
              Entiendo que Proxima WorkTech, LLC puede recibir una compensación
              financiera a cambio de revisar la información que proporciono.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              I understand that I am entitled to receive a copy of any data I
              provide to Proxima WorkTech, LLC by submitting a written request
              to support@proximawork.com.
            `,
            [Language.SPANISH]: `
              Entiendo que tengo derecho a recibir una copia de cualquier dato
              que proporcione a Proxima WorkTech, LLC presentando una solicitud
              por escrito a support@proximawork.com.
            `,
          }[language]}
        </p>
        <fieldset>
          <div className="space-y-5 mt-8">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  checked={acceptRelease}
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  id="agreeToRelease"
                  name="agreeToRelease"
                  onChange={(evt) => {
                    setAcceptRelease(evt.target.checked);
                  }}
                  type="checkbox"
                />
              </div>
              <div className="ml-3 leading-6 mb-4">
                <label
                  htmlFor="agreeToRelease"
                  className="font-medium text-gray-900 cursor-pointer"
                >
                  {{
                    [Language.ENGLISH]: 'I agree to the above release conditions',
                    [Language.SPANISH]: 'Estoy de acuerdo con las condiciones de liberación anteriores',
                  }[language]}
                </label>
              </div>
            </div>
          </div>
        </fieldset>
        {submitState === 'ERROR' && <SubmitError />}
        <div className="flex w-full justify-center mb-8">
          <Button
            disabled={!acceptRelease || submitState === 'SUBMITTING'}
            loading={submitState === 'SUBMITTING'}
            onClick={handleSubmit}
          >
            {submitState === 'SUBMITTING' ? (
              {
                [Language.ENGLISH]: 'Submitting...',
                [Language.SPANISH]: 'Enviando...',
              }[language]
            ) : (
              {
                [Language.ENGLISH]: 'Agree and Submit',
                [Language.SPANISH]: 'Aceptar y Enviar',
              }[language]
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export function ErrorMessage({
  error,
}: {
  error: string;
}) {
  return (
    <p className="text-sm text-red-600">
      {error}
    </p>
  );
}

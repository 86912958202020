export class TemplateContextNotFoundError extends Error {
  constructor(path: string[]) {
    super(`Missing template context: ${path.join('.')}`);
    this.name = 'TemplateContextNotFoundError';
  }
}

export class UnsupportedTemplateFunctionError extends Error {
  constructor(functionName: string) {
    super(`Unsupported template function: ${functionName}`);
    this.name = 'UnsupportedTemplateFunctionError';
  }
}

import { ReactNode } from 'react';

import { ErrorMessage } from './ErrorMessage';
import { FormLabel } from './FormLabel';
import { FieldSubprompt } from './FieldSubprompt';

export function FieldWrapper({
  children,
  className,
  error,
  id,
  prompt,
  subprompt,
}: {
  children: ReactNode;
  className?: string;
  error?: string;
  id?: string;
  prompt?: string;
  subprompt?: string;
}) {
  return (
    <div className={`flex flex-col gap-y-1 ${className ?? ''}`}>
      <div className="flex flex-col gap-y-2">
        {prompt !== undefined && (
          <FormLabel hasError={error !== undefined} id={id} label={prompt} />
        )}
        {subprompt !== undefined && (
          <FieldSubprompt subprompt={subprompt} />
        )}
        {children}
      </div>
      {error !== undefined && (
        <ErrorMessage error={error} />
      )}
    </div>
  );
}

export enum DropdownDirection {
  LEFT,
  RIGHT,
}

export enum FieldType {
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
  HEIGHT_WEIGHT = 'HEIGHT_WEIGHT',
  INFO = 'INFO',
  INLINE_RADIO = 'INLINE_RADIO',
  SELECT = 'SELECT',
  STACKED_CHECKBOX = 'STACKED_CHECKBOX',
  STACKED_RADIO = 'STACKED_RADIO',
  TEXTAREA = 'TEXTAREA',
}

import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { Responses } from '@/features/form';
import { QuestionnaireResult } from '@/features/result';

import { AppStage, DrawerContent } from './constants';
import { DynamicAppInitializer } from './types';

export type AppContextValue = {
  appInitializer: DynamicAppInitializer;
  finalResponses: Responses | null;
  questionnairePrn: string | null;
  setAppStage: Dispatch<SetStateAction<AppStage>>;
  setDrawerContent: Dispatch<SetStateAction<DrawerContent | null>>;
  setResult: Dispatch<SetStateAction<QuestionnaireResult | null>>;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  submitting: boolean;
};

export const appContext = createContext<AppContextValue>({} as AppContextValue);

export function useAppContext() {
  return useContext(appContext);
}

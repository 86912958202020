import { Dispatch, SetStateAction, useState } from 'react';

import { DrawerContent, LocationStub, useAppContext } from '@/features/app';
import { Form, FormErrors, Responses, getPageUiProps } from '@/features/form';
import { useI18nContext } from '@/features/i18n';
import { render } from '@/features/templating';

import { DynamicForm as DynamicFormData, DynamicFormTemplate, RiskAssessmentFormRenderContext } from '../types';
import { blankResponse, cycleResponses } from '../utils/cycle';
import { expandDynamicForm } from '../utils/expand';
import { minifyPages } from '../utils/minify';
import { validatePage } from '../utils/validate';

export function DynamicForm({
  location,
  responsePreloads,
  setFinalResponses,
  template,
}: {
  location: LocationStub;
  responsePreloads: Responses;
  setFinalResponses: Dispatch<SetStateAction<Responses | null>>;
  template: DynamicFormTemplate;
}) {
  const { dateOfBirth, ...phiPreloads } = responsePreloads;

  if (typeof dateOfBirth !== 'string') {
    throw new Error();  // TODO
  }

  const { setDrawerContent } = useAppContext();
  const { language } = useI18nContext();

  const [errors, setErrors] = useState<FormErrors>({});

  const baseContext: RiskAssessmentFormRenderContext = {
    employee: {
      dateOfBirth,
    },
    language,
    location: {
      name: location.name,
    },
    responses: {},
  };

  const [responses, setResponses] = useState<Responses>(
    () => {
      // Render the form once with no responses to start with nulls for all
      // unconditionally included questions
      const initialForm = render({
        context: {
          ...baseContext,
          responses: {},
        },
        template,
      }) as DynamicFormData;

      const blankResponses = Object.entries(initialForm.questions).reduce(
        (acc, [questionId, question]) => ({
          ...acc,
          ...((question.include ?? true) && {
            [questionId]: blankResponse(question),
          }),
        }),
        {},
      );

      return { ...blankResponses, ...phiPreloads };
    },
  );

  const formProps = expandDynamicForm(
    render({
      context: {
        ...baseContext,
        responses,
      },
      template,
    }) as DynamicFormData,
  );

  const minifiedPages = minifyPages(formProps);

  return (
    <Form
      errors={errors}
      onComplete={() => {
        setFinalResponses(responses);
        setDrawerContent(DrawerContent.ATTESTATION);
      }}
      onGoNext={(currentPageId) => {
        const pageProps = getPageUiProps({
          allPageUiProps: minifiedPages,
          id: currentPageId,
        });

        const errors = validatePage({
          errorMessages: formProps.ui.errorMessages,
          pageProps,
          questions: formProps.questions,
          responses,
        })

        setErrors(errors);

        const canGoNext = Object.keys(errors).length === 0;

        return canGoNext;
      }}
      onSetResponse={(questionId, value) => {
        const cycledResponses = cycleResponses({
          context: {
            ...baseContext,
            responses: {
              ...responses,
              [questionId]: value,
            },
          },
          questionsTemplate: template.questions,
        });

        setResponses(cycledResponses);
      }}
      pages={minifiedPages}
      responses={responses}
    />
  );
}

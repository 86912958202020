import { FormFieldUiProps, FormModuleUiProps, FormPageUiProps } from '@/features/form';

import { DynamicForm } from '../types';
import { isQuestionFormFieldUiProps } from '@/features/form/utils';

export function minifyPages(dynamicFormProps: DynamicForm): FormPageUiProps[] {
  const minifiedPages: FormPageUiProps[] = [];

  const includedQuestionIds: string[] = [];

  for (const pageProps of dynamicFormProps.ui.pages) {
    const minifiedModules: FormModuleUiProps[] = [];

    for (const moduleProps of pageProps.modules) {
      const minifiedFields: FormFieldUiProps[] = [];

      for (const fieldProps of moduleProps.fields) {
        if (isQuestionFormFieldUiProps(fieldProps)) {
          if (typeof fieldProps.questionId === 'string') {
            const questionId = fieldProps.questionId;
            const question = dynamicFormProps.questions[questionId];

            if (question === undefined) {
              throw new Error();  // TODO | Question doesn't exist, template error
            }

            const includeQuestion = question.include ?? true;
            const includeField = fieldProps.include ?? true;

            // Questions should appear only one time in the form, at the earliest
            // appropriate location
            if (includeQuestion && includeField && !includedQuestionIds.includes(questionId)) {
              includedQuestionIds.push(questionId);
              minifiedFields.push(fieldProps);
            }
          } else {
            // For now, the logic governing compound fields is not wholly
            // consistent; a field is included if ANY of its subfields should be
            // included, and no checking is done to see if the subfields have
            // appeared anywhere else in the form. Because compound fields are
            // used only in very specific locations (height/weight and BP), this
            // is workable.
            const questionsIds = Object.values(fieldProps.questionId);

            let includeAnyQuestions = false;

            for (const questionId of questionsIds) {
              const question = dynamicFormProps.questions[questionId];

              if (question === undefined) {
                throw new Error();  // TODO | Question doesn't exist, template error
              }

              const includeQuestion = question.include ?? true;

              if (includeQuestion) {
                includeAnyQuestions = true;
              }
            }

            if (includeAnyQuestions) {
              includedQuestionIds.push(...questionsIds);
              minifiedFields.push(fieldProps);
            }
          }
        } else {
          // No associated question; whether or not to include the field rests
          // solely on the field itself
          const includeField = fieldProps.include ?? true;

          if (includeField) {
            minifiedFields.push(fieldProps);
          }
        }
      }

      if (minifiedFields.length > 0) {
        minifiedModules.push({
          ...moduleProps,
          fields: minifiedFields,
        });
      }
    }

    if (minifiedModules.length > 0) {
      minifiedPages.push({
        ...pageProps,
        modules: minifiedModules,
      });
    }
  }

  return minifiedPages;
}

import { useState } from 'react';

import { Button } from '@/components/Button';
import { Page, useAppContext } from '@/features/app';
import { Language, useI18nContext } from '@/features/i18n';

import { Card } from './Card';
import { AppStage, DrawerContent } from '../constants';
import { VerticalSpacer } from './VerticalSpacer';

export function StartPage() {
  const { appInitializer, setAppStage, setDrawerContent } = useAppContext();
  const { language } = useI18nContext();

  const [agree, setAgree] = useState(false);

  const employerName = appInitializer.employer.name;
  const spName = appInitializer.serviceProvider !== null
    ? appInitializer.serviceProvider.name
    : null;

  const gcdmse = appInitializer.modules.groundCannabisDustMedicalSurveillanceEvaluation;
  const anyMse = gcdmse;  // FUTURE | Silica, etc
  const rme = appInitializer.modules.respiratorMedicalEvaluation;

  const typeOfQuestionnaire = {
    [Language.ENGLISH]: anyMse && rme
      ? `Medical Evaluation and Screening`
      : anyMse
        ? 'Medical Screening'
        : 'Medical Evaluation',
    [Language.SPANISH]: anyMse && rme
      ? `SPANISH`
      : anyMse
        ? 'SPANISH'
        : 'Evaluación Médica',
  };

  const explanation = {
    [Language.ENGLISH]: anyMse && rme
      ? `
        Based on your job role, ${employerName} is required to use this
        questionnaire to evaluate your health for respirator use while working,
        and to screen you for symptoms of allergy and/or disease related to ${
          // eslint-disable-next-line -- Remove disable once more kinds of MSE
          gcdmse ? 'ground cannabis dust' : ''
        }.
      `
      : anyMse
        ? `
          Based on your job role, ${employerName} is required to use this
          questionnaire to screen you for symptoms of allergy and/or disease
          related to ${
            // eslint-disable-next-line -- Remove disable once more kinds of MSE
            gcdmse ? 'ground cannabis dust' : ''
          }
        `
        : `
          Based on your job role, ${employerName} is required to use this
          questionnaire to evaluate your health for respirator use while
          working.
        `,
    [Language.SPANISH]: anyMse && rme
    ? 'SPANISH'
    : anyMse
      ? 'SPANISH'
      : 'SPANISH',
  };

  return (
    <Page
      blurb={{
        [Language.ENGLISH]: `
          Welcome to the ${employerName} ${typeOfQuestionnaire[Language.ENGLISH]}
          Questionnaire${
            spName !== null ? `, administered by ${spName}` : ''
          }. ${explanation[Language.ENGLISH]}
        `,
        [Language.SPANISH]: `
          Bienvenido al Cuestionario de ${typeOfQuestionnaire[Language.SPANISH]}
          de ${employerName}${
            spName !== null ? `, administrado por ${spName}` : ''
          }. ${explanation[Language.SPANISH]}
        `,
      }[language]}
      title={{
        [Language.ENGLISH]: 'Welcome',
        [Language.SPANISH]: 'Bienvenido',
      }[language]}
    >
      <VerticalSpacer>
        <Card>
          <h3 className="mb-2 text-lg font-medium">
            {{
              [Language.ENGLISH]: 'Before You Begin',
              [Language.SPANISH]: 'Antes de Empezar',
            }[language]}
          </h3>
          <p className="mb-2">
            {{
              [Language.ENGLISH]: 'You will need the following information before you proceed past this page:',
              [Language.SPANISH]: 'Necesitará la siguiente información antes de pasar de esta página:'
            }[language]}
          </p>
          <ol className="list-decimal ml-8">
            <li>
              {{
                [Language.ENGLISH]: 'Your job role',
                [Language.SPANISH]: 'Su función laboral',
              }[language]}
            </li>
            {anyMse && (
              <li>
                {{
                  [Language.ENGLISH]: 'Details about your work history',
                  [Language.SPANISH]: 'SPANISH'
                }[language]}
              </li>
            )}
            <li>
              {{
                [Language.ENGLISH]: 'Details about any illnesses, injuries, or conditions you may have',
                [Language.SPANISH]: 'Detalles sobre cualquier enfermedad, lesión o afección que pueda padecer',
              }[language]}
            </li>
            <li>
              {{
                [Language.ENGLISH]: '15 minutes to complete the form',
                [Language.SPANISH]: '15 minutos para rellenar el formulario',
              }[language]}
            </li>
          </ol>
        </Card>
        <Card>
          <h3 className="mb-2 text-lg font-medium">
            {{
              [Language.ENGLISH]: 'Terms and Privacy',
              [Language.SPANISH]: 'Términos y Privacidad',
            }[language]}
          </h3>
          <p className="mb-8">
            {{
              [Language.ENGLISH]: 'You must read and agree to the ',
              [Language.SPANISH]: 'Debe leer y aceptar las ',
            }[language]}
            <a className="text-primary hover:underline cursor-pointer" onClick={() => {
              setDrawerContent(DrawerContent.TOU);
            }}>
              {{
                [Language.ENGLISH]: 'Terms of Use',
                [Language.SPANISH]: 'Condiciones de Uso',
              }[language]}
            </a>
            {{
              [Language.ENGLISH]: ' and ',
              [Language.SPANISH]: ' y la ',
            }[language]}
            <a className="text-primary hover:underline cursor-pointer" onClick={() => {
              setDrawerContent(DrawerContent.PRIVACY_POLICY);
            }}>
              {{
                [Language.ENGLISH]: 'Privacy Policy',
                [Language.SPANISH]: 'Política de Privacidad',
              }[language]}
            </a>
            {{
              [Language.ENGLISH]: ' of this site before beginning.',
              [Language.SPANISH]: ' de este sitio antes de comenzar.',
            }[language]}
          </p>
          <fieldset>
            <div className="space-y-5">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    checked={agree}
                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                    id="agreeToTou"
                    name="agreeToTou"
                    onChange={(evt) => {
                      setAgree(evt.target.checked);
                    }}
                    type="checkbox"
                  />
                </div>
                <div className="ml-3 leading-6 mb-4">
                  <label
                    htmlFor="agreeToTou"
                    className="font-medium text-gray-900 cursor-pointer"
                  >
                    {{
                      [Language.ENGLISH]: 'I have read and agree to the terms of use and privacy policy of this site.',
                      [Language.SPANISH]: 'Leí y acepto las condiciones de uso y la política de privacidad de este sitio.',
                    }[language]}
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
        </Card>
        <div className="flex w-full justify-center">
          <Button disabled={!agree} onClick={() => {
            if (agree) {
              setAppStage(AppStage.PII);
            }
          }}>
            {{
              [Language.ENGLISH]: 'Continue',
              [Language.SPANISH]: 'Continuar',
            }[language]}
          </Button>
        </div>
      </VerticalSpacer>
    </Page>
  );
}

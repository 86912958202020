import { Response, Responses } from '@/features/form';
import { render } from '@/features/templating';
import { DataType } from '@/types/data';
import { JsonObject } from '@/types/json';
import { isJsonArray } from '@/utils/json';

import {
  DynamicFormQuestion,
  DynamicFormQuestions,
  RiskAssessmentFormRenderContext,
} from '../types';

export function blankResponse(question: DynamicFormQuestion): Response {
  switch (question.type) {
    case DataType.ARRAY: {
      return [];
    }
    case DataType.STRING: {
      return '';
    }
    case DataType.NUMBER: {
      return null;
    }
    default: {
      throw new Error();  // Unsupported question type
    }
  }
}

export function cycleResponses({
  context,
  questionsTemplate,
}: {
  context: RiskAssessmentFormRenderContext;
  questionsTemplate: JsonObject;
}): Responses {
  let uncycledResponses = context.responses;
  let cycledResponses: Responses;
  let recycle: boolean;

  const maxCycles = 10;
  let numCycles = 1;

  do {
    cycledResponses = cycleResponsesOnce({ context, questionsTemplate });

    recycle = !responsesEqual(uncycledResponses, cycledResponses);

    uncycledResponses = cycledResponses;

    numCycles++;

    if (numCycles > maxCycles) {
      throw new Error();  // TODO | Maximum cycle depth reached
    }
  } while (recycle);

  return cycledResponses;
}

function cycleResponsesOnce({
  context,
  questionsTemplate,
}: {
  context: RiskAssessmentFormRenderContext;
  questionsTemplate: JsonObject;
}): Responses {
  const questions = render({
    context,
    template: questionsTemplate,
  }) as DynamicFormQuestions;

  const newResponses = Object.entries(questions).reduce(
    (acc, [questionId, question]) => {
      const include = question.include ?? true;

      return {
        ...acc,
        ...(include && {
          [questionId]: context.responses[questionId] ?? blankResponse(question),
        }),
      };
    },
    {},
  );

  return newResponses;
}

function responsesEqual(r1: Responses, r2: Responses): boolean {
  const keys1 = Object.keys(r1);
  const keys2 = Object.keys(r2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const value1 = r1[key];
    const value2 = r2[key];

    // Two empty arrays are equal to each other
    if (value1 !== undefined && isJsonArray(value1) && value1.length === 0
        && value2 !== undefined && isJsonArray(value2) && value2.length === 0) {
      continue;
    }

    // In Javascript, NaN !== NaN so handle it specially
    if (typeof value1 === 'number' && typeof value2 === 'number'
        && isNaN(value1) && isNaN(value2)) {
      continue;
    }

    if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';

import { Markdown } from '@/features/dynamic-form';

import { InfoFieldUiProps } from '../types';

export function InfoField({
  text,
}: InfoFieldUiProps) {
  return (
    <div className="flex flex-row items-start gap-4">
      <div className="w-6">
        <ChevronDoubleRightIcon className="h-6 w-6" />
      </div>
      <div>
        <Markdown>
          {text}
        </Markdown>
      </div>
    </div>
  );
}

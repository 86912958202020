import { XMarkIcon } from '@heroicons/react/20/solid';

import { Language, useI18nContext } from '@/features/i18n';

import { DrawerContent } from '../constants';
import { useAppContext } from '../contexts';

import { Title } from './Title';

export function TermsOfUse() {
  const { setDrawerContent } = useAppContext();
  const { language } = useI18nContext();

  return (
    <div className="p-8">
      <div className="mx-auto max-w-3xl sm:px-6 lg:px-8 flex flex-col gap-4">
        <div className="text-right">
          <button onClick={() => {
            setDrawerContent(null);
          }} type="button" className="bg-white border-gray-400 border-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <Title title={{
          [Language.ENGLISH]: 'Terms of Use',
          [Language.SPANISH]: 'Condiciones de Uso',
        }[language]} />
        <p>
          {{
            [Language.ENGLISH]: `
              Welcome to proximawork.com. This Agreement is between you (the
              “User”) of proximawork.com and Proxima WorkTech, LLC (“Proxima”)
              which operates proximawork.com.
            `,
            [Language.SPANISH]: `
              Bienvenido a proximawork.com. Este Acuerdo es entre usted (el
              "Usuario") de proximawork.com y Proxima WorkTech, LLC ("Proxima")
              que opera proximawork.com.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              The Site is comprised of various web pages and is offered to you
              conditioned on your acceptance without modification of the terms,
              conditions, and notices contained herein (the "Terms"). Your use
              of proximawork.com constitutes your agreement to all such Terms.
              Please read these terms carefully and keep a copy of them for your
              reference. Proximawork.com is a website that provides Occupational
              Health and Safety Software and associated Services (the “Site”).
            `,
            [Language.SPANISH]: `
              El Sitio se compone de varias páginas web y se le ofrece a
              condición de que acepte sin modificaciones los términos,
              condiciones y avisos aquí contenidos (los "Términos"). Su uso de
              proximawork.com constituye su Acuerdo con todos esos Términos. Por
              favor, lea estos términos cuidadosamente y guarde una copia de los
              mismos para su referencia. Proximawork.com es un sitio web que
              proporciona Software de Salud y Seguridad Ocupacional y Servicios
              asociados (el "Sitio").
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Privacy',
            [Language.SPANISH]: 'Privacidad',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: 'Your use of proximawork.com is subject to Proxima\'s ',
            [Language.SPANISH]: 'Su uso de proximawork.com está sujeto a la ',
          }[language]}
          <a className="text-primary hover:underline cursor-pointer" onClick={() => {
            setDrawerContent(DrawerContent.PRIVACY_POLICY);
          }}>
            {{
              [Language.ENGLISH]: 'Privacy Policy',
              [Language.SPANISH]: 'Política de Privacidad',
            }[language]}
          </a>
          {{
            [Language.ENGLISH]: `.
              Please review our Privacy Policy, which also governs the Site
              and informs users of our data collection practices.
            `,
            [Language.SPANISH]: `
              de Proxima. Por favor, revise nuestra Política de Privacidad, que
              también rige el Sitio e informa a los usuarios de nuestras
              prácticas de recopilación de datos.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Electronic Communications',
            [Language.SPANISH]: 'Comunicaciones Electrónicas',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              Visiting proximawork.com or sending emails to Proxima constitutes
              electronic communications. You consent to receive electronic
              communications and you agree that all agreements, notices,
              disclosures and other communications that we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communications be in writing.
            `,
            [Language.SPANISH]: `
              Visitar proximawork.com o enviar correos electrónicos a Proxima
              constituyen comunicaciones electrónicas. Usted da su
              consentimiento para recibir comunicaciones electrónicas y acepta
              que todos los Acuerdos, avisos, divulgaciones y otras
              comunicaciones que le proporcionemos electrónicamente, por correo
              electrónico y en el Sitio, satisfacen cualquier requisito legal de
              que dichas comunicaciones sean por escrito.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Your Account',
            [Language.SPANISH]: 'Su Cuenta',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              If you use this Site, you are responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your computer, and you agree to accept responsibility
              for all activities that occur under your account or password. You
              may not assign or otherwise transfer your account to any other
              person or entity. You acknowledge that Proxima is not responsible
              for third party access to your account that results from theft or
              misappropriation of your account. Proxima and its associates
              reserve the right to refuse or cancel service, terminate accounts,
              or remove or edit content in our sole discretion.
            `,
            [Language.SPANISH]: `
              Si utiliza este Sitio, usted es responsable de mantener la
              confidencialidad de su cuenta y contraseña y de restringir el
              acceso a su computadora, y se compromete a aceptar la
              responsabilidad de todas las actividades que ocurran bajo su
              cuenta o contraseña. No podrá ceder ni transferir de ningún otro
              modo su cuenta a ninguna otra persona o entidad. Usted reconoce
              que Proxima no es responsable por el acceso de terceros a su
              cuenta que resulte del robo o apropiación indebida de la misma.
              Proxima y sus asociados se reservan el derecho de rechazar o
              cancelar el servicio, cancelar cuentas o eliminar o editar
              contenido a nuestra entera discreción.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Children Under Thirteen',
            [Language.SPANISH]: 'Menores de Trece Años',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              Proxima does not knowingly collect, either online or offline,
              personal information from persons under the age of thirteen. If
              you are under 18, you may use proximawork.com only with the
              permission of a parent or guardian.
            `,
            [Language.SPANISH]: `
              Proxima no recopila a sabiendas, ni en línea ni fuera de línea,
              información personal de personas menores de trece años. Si usted
              es menor de dieciocho años, puede utilizar proximawork.com sólo
              con el permiso de un padre o tutor.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Links to Third Party Sites/Third Party Services',
            [Language.SPANISH]: 'Enlaces a Sitios de Terceros/Servicios de Terceros',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              This Site may contain links to other websites ("Linked Sites").
              The Linked Sites are not under the control of Proxima and Proxima
              is not responsible for the contents of any Linked Site, including
              without limitation any link contained in a Linked Site, or any
              changes or updates to a Linked Site. Proxima is providing these
              links to you only as a convenience, and the inclusion of any link
              does not imply endorsement by Proxima of the Linked Site or any
              association with its operators.
            `,
            [Language.SPANISH]: `
              Este Sitio puede contener enlaces a otros sitios web ("Sitios
              Vinculados"). Los Sitios Vinculados no están bajo el control de
              Proxima y Proxima no es responsable de los contenidos de ningún
              Sitio Vinculado, incluyendo sin limitación cualquier vínculo
              contenido en un Sitio Vinculado, o cualquier cambio o
              actualización de un Sitio Vinculado. Proxima le proporciona estos
              vínculos sólo para su conveniencia, y la inclusión de cualquier
              vínculo no implica aprobación por parte de Proxima del Sitio
              Vinculado ni asociación alguna con sus operadores.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              Certain services made available via this Site are delivered by
              third party sites and/or organizations. By using any product,
              service or functionality originating from the proximawork.com
              domain, you hereby acknowledge and consent that Proxima may share
              such information and data with any third party with whom Proxima
              has a contractual relationship to provide the requested product,
              service or functionality on behalf of proximawork.com users and
              customers so long as the data being shared, or the act of sharing
              the data, does not violate any federal or local law.
            `,
            [Language.SPANISH]: `
              Ciertos servicios disponibles a través de este Sitio son prestados
              por sitios y/u organizaciones de terceros. Al utilizar cualquier
              producto, servicio o funcionalidad que se origine en el dominio
              proximawork.com, usted reconoce y consiente mediante el presente
              documento que Proxima podrá compartir dicha información y datos
              con cualquier tercero con el que Proxima tenga una relación
              contractual para proporcionar el producto, servicio o
              funcionalidad solicitados en nombre de los usuarios y clientes de
              proximawork.com siempre y cuando los datos que se compartan, o el
              acto de compartir los datos, no infrinjan ninguna ley federal o
              local.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'No Unlawful or Prohibited Use/Intellectual Property',
            [Language.SPANISH]: 'Ningún Uso Ilegal o Prohibido/Propiedad Intelectual',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              You are granted a non-exclusive, non-transferable, revocable
              license to access and use proximawork.com strictly in accordance
              with these terms of use. As a condition of your use of the Site,
              you warrant to Proxima that you will not use the Site for any
              purpose that is unlawful or prohibited by these Terms. You may not
              use the Site in any manner which could damage, disable,
              overburden, or impair the Site or interfere with any other party's
              use and enjoyment of the Site. You may not obtain or attempt to
              obtain any materials or information through any means not
              intentionally made available or provided for through the Site.
            `,
            [Language.SPANISH]: `
              Se le concede una licencia no exclusiva, intransferible y
              revocable para acceder y utilizar proximawork.com estrictamente de
              acuerdo con estas condiciones de uso. Como condición para el uso
              del Sitio, usted garantiza a Proxima que no utilizará el Sitio
              para ningún fin ilegal o prohibido por estas Condiciones. No podrá
              utilizar el Sitio de ninguna manera que pueda dañar, deshabilitar,
              sobrecargar o deteriorar el Sitio o interferir con el uso y
              disfrute del Sitio por parte de terceros. No podrá obtener ni
              intentar obtener ningún material o información a través de ningún
              medio que no se haya puesto a disposición o proporcionado
              intencionadamente a través del Sitio.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              All content included as part of the Site, such as text, graphics,
              logos, images, as well as the compilation thereof, and any
              software used on the Site, is the property of Proxima or its
              suppliers and protected by copyright and other laws that protect
              intellectual property and proprietary rights. You agree to observe
              and abide by all copyright and other proprietary notices, legends
              or other restrictions contained in any such content and will not
              make any changes thereto.
            `,
            [Language.SPANISH]: `
              Todo el contenido incluido como parte del Sitio, como texto,
              gráficos, logotipos, imágenes, así como la compilación del mismo,
              y cualquier software utilizado en el Sitio, es propiedad de
              Proxima o de sus proveedores y está protegido por derechos de
              autor y otras leyes que protegen la propiedad intelectual y los
              derechos de propiedad. Usted se compromete a respetar y acatar
              todos los avisos de derechos de autor y otros derechos de
              propiedad, leyendas u otras restricciones contenidas en dichos
              contenidos y no realizará ningún cambio en los mismos.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              You will not modify, publish, transmit, reverse engineer,
              participate in the transfer or sale, create derivative works, or
              in any way exploit any of the content, in whole or in part, found
              on the Site. Proxima content is not for resale. Your use of the
              Site does not entitle you to make any unauthorized use of any
              protected content, and you will not delete or alter any
              proprietary rights or attribution notices in any content.
            `,
            [Language.SPANISH]: `
              Usted no modificará, publicará, transmitirá, realizará ingeniería
              inversa, participará en la transferencia o venta, creará trabajos
              derivados, o explotará de cualquier forma cualquiera de los
              contenidos, en su totalidad o en parte, que se encuentren en el
              Sitio. El contenido de Proxima no está destinado a la reventa. Su
              uso del Sitio no le da derecho a hacer ningún uso no autorizado de
              ningún contenido protegido, y usted no borrará ni alterará ningún
              derecho de propiedad o aviso de atribución en ningún contenido.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              You will use protected content solely for your personal use and
              will make no other use of the content without the express written
              permission of Proxima and the copyright owner. You agree that you
              do not acquire any ownership rights in any protected content. We
              do not grant you any licenses, express or implied, to the
              intellectual property of Proxima or our licensors except as
              expressly authorized by these Terms.
            `,
            [Language.SPANISH]: `
              Usted utilizará el contenido protegido únicamente para su uso
              personal y no hará ningún otro uso del contenido sin el permiso
              expreso por escrito de Proxima y del propietario de los derechos
              de autor. Usted acepta que no adquiere ningún derecho de propiedad
              sobre ningún contenido protegido. No le otorgamos ninguna
              licencia, expresa o implícita, sobre la propiedad intelectual de
              Proxima o de nuestros licenciantes, salvo en los casos
              expresamente autorizados por estas Condiciones.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'International Users',
            [Language.SPANISH]: 'Usuarios Internacionales',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              The Service is controlled, operated and administered by Proxima
              from our offices within the USA. If you access the Service from a
              location outside the USA, you are responsible for compliance with
              all local laws. You agree that you will not use the Proxima
              Content accessed through proximawork.com in any country or in any
              manner prohibited by any applicable laws, restrictions, or
              regulations.
            `,
            [Language.SPANISH]: `
              El Servicio es controlado, operado y administrado por Proxima
              desde nuestras oficinas dentro de los EE. UU. Si accede al
              Servicio desde una ubicación fuera de los EE. UU., usted es
              responsable del cumplimiento de todas las leyes locales. Usted
              acepta que no utilizará el Contenido de Proxima al que se accede a
              través de proximawork.com en ningún país ni de ninguna manera
              prohibida por las leyes, restricciones o reglamentaciones
              aplicables.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Indemnification',
            [Language.SPANISH]: 'Indemnización',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              You agree to indemnify, defend and hold harmless Proxima, its
              officers, directors, employees, agents and third parties, for any
              losses, costs, liabilities and expenses (including reasonable
              attorney's fees) relating to or arising out of your use of or
              inability to use the Site or services, any user postings made by
              you, your violation of any terms of this Agreement or your
              violation of any rights of a third party, or your violation of any
              applicable laws, rules or regulations. Proxima reserves the right,
              at its own cost, to assume the exclusive defense and control of
              any matter otherwise subject to indemnification by you, in which
              event you will fully cooperate with Proxima in asserting any
              available defenses.
            `,
            [Language.SPANISH]: `
              Usted acepta indemnizar, defender y eximir de responsabilidad a
              Proxima, sus funcionarios, directores, empleados, agentes y
              terceros, por cualquier pérdida, costo, responsabilidad y gasto
              (incluidos los honorarios razonables de abogados) relacionados con
              o que surjan de su uso o imposibilidad de uso del Sitio o los
              servicios, cualquier publicación de usuario realizada por usted,
              su violación de cualquiera de los términos de este Acuerdo o su
              violación de cualquier derecho de un tercero, o su violación de
              cualquier ley, norma o reglamento aplicable. Proxima se reserva el
              derecho, a su propio costo, de asumir la defensa y el control
              exclusivos de cualquier asunto sujeto a indemnización por parte de
              usted, en cuyo caso usted cooperará plenamente con Proxima para
              hacer valer cualquier defensa disponible.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Arbitration',
            [Language.SPANISH]: 'Arbitraje',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              In the event the parties are not able to resolve any dispute
              between them arising out of or concerning these Terms and
              Conditions, or any provisions hereof, whether in contract, tort,
              or otherwise at law or in equity for damages or any other relief,
              then such dispute shall be resolved only by final and binding
              arbitration pursuant to the Federal Arbitration Act, conducted by
              a single neutral arbitrator and administered by the American
              Arbitration Association, or a similar arbitration service selected
              by the parties, in Williamson County, Texas. The arbitrator's
              award shall be final, and judgment may be entered upon it in any
              court having jurisdiction. If any legal or equitable action,
              proceeding or arbitration arises out of or concerns these Terms
              and Conditions, the prevailing party shall be entitled to recover
              its costs and reasonable attorney's fees. The parties agree to
              arbitrate all disputes and claims regarding these Terms and
              Conditions or any disputes arising as a result of these Terms and
              Conditions, whether directly or indirectly, including Tort claims
              that are a result of these Terms and Conditions. The parties agree
              that the Federal Arbitration Act governs the interpretation and
              enforcement of this provision. The entire dispute, including the
              scope and enforceability of this arbitration provision shall be
              determined by the Arbitrator. This arbitration provision shall
              survive the termination of these Terms and Conditions.
            `,
            [Language.SPANISH]: `
              En el caso de que las partes no sean capaces de resolver cualquier
              disputa entre ellas que surja de o en relación con estos Términos
              y Condiciones, o cualquiera de sus disposiciones, ya sea en
              contrato, agravio, o de otra manera en derecho o en equidad por
              daños o cualquier otra reparación, entonces dicha disputa se
              resolverá únicamente mediante arbitraje final y vinculante de
              conformidad con la Ley Federal de Arbitraje, llevado a cabo por un
              único árbitro neutral y administrado por la Asociación
              Estadounidense de Arbitraje, o un servicio de arbitraje similar
              seleccionado por las partes, en el condado de Williamson, Texas.
              El laudo del árbitro será definitivo y se podrá dictar sentencia
              sobre el mismo en cualquier tribunal competente. Si cualquier
              acción legal o equitativa, procedimiento o arbitraje surge de o se
              refiere a estos Términos y Condiciones, la parte que prevalezca
              tendrá derecho a recuperar sus costos y honorarios razonables de
              abogado. Las partes acuerdan someter a arbitraje todas las
              disputas y reclamaciones relativas a las presentes Condiciones o a
              cualquier disputa que surja como consecuencia de las mismas, ya
              sea directa o indirectamente, incluidas las reclamaciones por
              agravio que sean consecuencia de las presentes Condiciones. Las
              partes acuerdan que la Ley Federal de Arbitraje rige la
              interpretación y aplicación de esta disposición. La totalidad de
              la disputa, incluyendo el alcance y la aplicabilidad de esta
              disposición de arbitraje, será determinada por el Árbitro. Esta
              disposición de arbitraje sobrevivirá a la terminación de estos
              Términos y Condiciones.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Class Action Waiver',
            [Language.SPANISH]: 'Renuncia a la Demanda Colectiva',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              Any arbitration under these Terms and Conditions will take place
              on an individual basis; class arbitrations and
              class/representative/collective actions are not permitted. THE
              PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
              IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
              PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL
              ACTION AGAINST THE OTHER. Further, unless both you and Proxima
              agree otherwise, the arbitrator may not consolidate more than one
              person's claims, and may not otherwise preside over any form of a
              representative or class proceeding.
            `,
            [Language.SPANISH]: `
              Cualquier arbitraje en virtud de estos Términos y Condiciones
              tendrá lugar de forma individual; no se permiten los arbitrajes de
              clase ni las acciones de clase/representativas/colectivas. LAS
              PARTES ACUERDAN QUE UNA PARTE PUEDE PRESENTAR RECLAMACIONES CONTRA
              LA OTRA SÓLO A TÍTULO INDIVIDUAL, Y NO COMO DEMANDANTE O MIEMBRO
              DE UNA CLASE EN CUALQUIER PROCEDIMIENTO DE CLASE PUTATIVO,
              COLECTIVO Y/O REPRESENTATIVO, COMO POR EJEMPLO EN FORMA DE UNA
              ACCIÓN DE UN ABOGADO GENERAL PRIVADO CONTRA LA OTRA. Además, a
              menos que tanto usted como Proxima acuerden lo contrario, el
              árbitro no podrá consolidar las reclamaciones de más de una
              persona, y no podrá presidir ninguna forma de procedimiento
              representativo o colectivo.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Liability Disclaimer',
            [Language.SPANISH]: 'Descargo de Responsabilidad',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
              AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
              TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
              INFORMATION HEREIN. PROXIMA AND/OR ITS SUPPLIERS MAY MAKE
              IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME. PROXIMA
              AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
              SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
              OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
              GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
              SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS
              IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. PROXIMA WORKTECH
              AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS
              WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
              RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
              NON-INFRINGEMENT.
            `,
            [Language.SPANISH]: `
              LA INFORMACIÓN, EL SOFTWARE, LOS PRODUCTOS Y LOS SERVICIOS
              INCLUIDOS O DISPONIBLES A TRAVÉS DEL SITIO PUEDEN INCLUIR
              IMPRECISIONES O ERRORES TIPOGRÁFICOS. PERIÓDICAMENTE, SE
              INTRODUCEN CAMBIOS EN LA INFORMACIÓN AQUÍ CONTENIDA. PROXIMA Y/O
              SUS PROVEEDORES PUEDEN REALIZAR MEJORAS Y/O CAMBIOS EN EL SITIO EN
              CUALQUIER MOMENTO. PROXIMA Y/O SUS PROVEEDORES NO SE
              RESPONSABILIZAN DE LA IDONEIDAD, FIABILIDAD, DISPONIBILIDAD,
              PUNTUALIDAD Y EXACTITUD DE LA INFORMACIÓN, SOFTWARE, PRODUCTOS,
              SERVICIOS Y GRÁFICOS RELACIONADOS CONTENIDOS EN EL SITIO PARA
              CUALQUIER FIN. EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE,
              TODA ESA INFORMACIÓN, SOFTWARE, PRODUCTOS, SERVICIOS Y GRÁFICOS
              RELACIONADOS SE PROPORCIONAN "TAL CUAL", SIN GARANTÍAS NI
              CONDICIONES DE NINGÚN TIPO. PROXIMA WORKTECH Y/O SUS PROVEEDORES
              DECLINAN POR LA PRESENTE TODAS LAS GARANTÍAS Y CONDICIONES EN
              RELACIÓN CON ESTA INFORMACIÓN, SOFTWARE, PRODUCTOS, SERVICIOS Y
              GRÁFICOS RELACIONADOS, INCLUIDAS TODAS LAS GARANTÍAS O CONDICIONES
              IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN DETERMINADO,
              TITULARIDAD Y NO INFRACCIÓN.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL PROXIMA AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
              INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
              ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
              LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
              CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY
              OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF
              OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
              PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE,
              OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON
              CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
              PROXIMA OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
              POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT
              ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
              OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
              IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY
              OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
              DISCONTINUE USING THE SITE.
            `,
            [Language.SPANISH]: `
              EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE, EN NINGÚN CASO
              PROXIMA Y/O SUS PROVEEDORES SERÁN RESPONSABLES DE NINGÚN DAÑO
              DIRECTO, INDIRECTO, PUNITIVO, INCIDENTAL, ESPECIAL, CONSECUENTE O
              DE CUALQUIER OTRO TIPO, INCLUIDOS, ENTRE OTROS, LOS DAÑOS POR
              PÉRDIDA DE USO, DATOS O BENEFICIOS, QUE SURJAN O ESTÉN
              RELACIONADOS DE ALGÚN MODO CON EL USO O EL RENDIMIENTO DEL SITIO,
              CON EL RETRASO O LA IMPOSIBILIDAD DE UTILIZAR EL SITIO O LOS
              SERVICIOS RELACIONADOS, LA PRESTACIÓN O LA IMPOSIBILIDAD DE
              PRESTAR SERVICIOS, O POR CUALQUIER INFORMACIÓN, SOFTWARE,
              PRODUCTOS, SERVICIOS Y GRÁFICOS RELACIONADOS OBTENIDOS A TRAVÉS
              DEL SITIO, O QUE SURJAN DE CUALQUIER OTRO MODO A PARTIR DEL USO
              DEL SITIO, YA SEA POR CONTRATO, AGRAVIO, NEGLIGENCIA,
              RESPONSABILIDAD ESTRICTA O DE CUALQUIER OTRO MODO, INCLUSO SI
              PROXIMA O CUALQUIERA DE SUS PROVEEDORES HA SIDO ADVERTIDO DE LA
              POSIBILIDAD DE DAÑOS. DEBIDO A QUE ALGUNOS ESTADOS/JURISDICCIONES
              NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE RESPONSABILIDAD POR DAÑOS
              CONSECUENCIALES O INCIDENTALES, ES POSIBLE QUE LA LIMITACIÓN
              ANTERIOR NO SE APLIQUE EN SU CASO. SI NO ESTÁ SATISFECHO CON
              ALGUNA PARTE DEL SITIO, O CON ALGUNA DE ESTAS CONDICIONES DE USO,
              SU ÚNICO Y EXCLUSIVO RECURSO ES DEJAR DE UTILIZAR EL SITIO.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Termination/Access Restriction',
            [Language.SPANISH]: 'Terminación/Restricción de Acceso',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              Proxima reserves the right, in its sole discretion, to terminate
              your access to the Site and the related services or any portion
              thereof at any time, without notice. To the maximum extent
              permitted by law, this agreement is governed by the laws of the
              State of Texas and you hereby consent to the exclusive
              jurisdiction and venue of courts in Texas in all disputes arising
              out of or relating to the use of the Site. Use of the Site is
              unauthorized in any jurisdiction that does not give effect to all
              provisions of these Terms, including, without limitation, this
              section.
            `,
            [Language.SPANISH]: `
              Proxima se reserva el derecho, a su entera discreción, de cancelar
              su acceso al Sitio y a los servicios relacionados o a cualquier
              parte del mismo en cualquier momento, sin previo aviso. En la
              medida máxima permitida por la ley, este Acuerdo se rige por las
              leyes del Estado de Texas y usted acepta por el presente la
              jurisdicción y competencia exclusivas de los tribunales de Texas
              en todas las disputas que surjan o estén relacionadas con el uso
              del Sitio. El uso del Sitio no está autorizado en ninguna
              jurisdicción que no dé efecto a todas las disposiciones de estos
              Términos, incluyendo, sin limitación, esta sección.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and Proxima as a result of
              this agreement or use of the Site. Proxima's performance of this
              agreement is subject to existing laws and legal process, and
              nothing contained in this agreement is in derogation of Proxima's
              right to comply with governmental, court and law enforcement
              requests or requirements relating to your use of the Site or
              information provided to or gathered by Proxima with respect to
              such use. If any part of this agreement is determined to be
              invalid or unenforceable pursuant to applicable law including, but
              not limited to, the warranty disclaimers and liability
              limitations set forth above, then the invalid or unenforceable
              provision will be deemed superseded by a valid, enforceable
              provision that most closely matches the intent of the original
              provision and the remainder of the agreement shall continue in
              effect.
            `,
            [Language.SPANISH]: `
              Usted acepta que no existe ninguna relación de empresa conjunta,
              asociación, empleo o agencia entre usted y Proxima como resultado
              de este Acuerdo o del uso del Sitio. El cumplimiento de este
              Acuerdo por parte de Proxima está sujeto a las leyes vigentes y al
              proceso legal, y nada de lo contenido en este Acuerdo deroga el
              derecho de Proxima a cumplir con las solicitudes o requisitos
              gubernamentales, judiciales y de aplicación de la ley relacionados
              con su uso del Sitio o con la información proporcionada a Proxima
              o recopilada por ésta con respecto a dicho uso. Si se determina
              que alguna parte de este Acuerdo es inválida o inaplicable de
              conformidad con la legislación vigente, incluidas, entre otras,
              las renuncias de garantía y las limitaciones de responsabilidad
              establecidas anteriormente, la disposición inválida o inaplicable
              se considerará sustituida por una disposición válida y aplicable
              que se acerque lo más posible a la intención de la disposición
              original y el resto del Acuerdo continuará en vigor.
            `,
          }[language]}
        </p>
        <p>
          {{
            [Language.ENGLISH]: `
              Unless otherwise specified herein, this agreement constitutes the
              entire agreement between the user and Proxima with respect to the
              Site and it supersedes all prior or contemporaneous communications
              and proposals, whether electronic, oral or written, between the
              user and Proxima with respect to the Site. A printed version of
              this agreement and of any notice given in electronic form shall be
              admissible in judicial or administrative proceedings based upon or
              relating to this agreement to the same extent and subject to the
              same conditions as other business documents and records originally
              generated and maintained in printed form. It is the express wish
              to the parties that this agreement and all related documents be
              written in English.
            `,
            [Language.SPANISH]: `
              A menos que se especifique lo contrario, este Acuerdo constituye
              el acuerdo completo entre el usuario y Proxima con respecto al
              Sitio y reemplaza todas las comunicaciones y propuestas anteriores
              o contemporáneas, ya sean electrónicas, orales o escritas, entre
              el usuario y Proxima con respecto al Sitio. Una versión impresa de
              este Acuerdo y de cualquier notificación realizada en forma
              electrónica será admisible en procedimientos judiciales o
              administrativos basados en este Acuerdo o relacionados con él, en
              la misma medida y con sujeción a las mismas condiciones que otros
              documentos y registros comerciales generados y conservados
              originalmente en forma impresa. Es el deseo expreso de las partes
              que este Acuerdo y todos los documentos relacionados sean
              redactados en inglés.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Changes to Terms',
            [Language.SPANISH]: 'Cambios en las Condiciones',
          }[language]}
        </h4>
        <p>
          {{
            [Language.ENGLISH]: `
              Proxima reserves the right, in its sole discretion, to change the
              Terms under which proximawork.com is offered. The most current
              version of the Terms will supersede all previous versions. Proxima
              encourages you to periodically review the Terms to stay informed
              of our updates.
            `,
            [Language.SPANISH]: `
              Proxima se reserva el derecho, a su entera discreción, de cambiar
              los Términos bajo los cuales se ofrece proximawork.com. La versión
              más actualizada de los Términos sustituirá a todas las versiones
              anteriores. Proxima le recomienda revisar periódicamente los
              Términos para mantenerse informado de nuestras actualizaciones.
            `,
          }[language]}
        </p>
        <h4 className="text-lg font-semibold underline">
          {{
            [Language.ENGLISH]: 'Contact Proxima',
            [Language.SPANISH]: 'Contactar a Proxima',
          }[language]}
        </h4>
        <p>
          contact@proximawork.com<br />
          +1 (888) 856-8288
        </p>
        <p>
          {{
            [Language.ENGLISH]: 'Effective as of January 1, 2024',
            [Language.SPANISH]: 'En vigor a partir del 1 de enero de 2024',
          }[language]}
        </p>
      </div>
    </div>
  );
}

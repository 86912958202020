import { Card, JobRoleSummary } from '@/features/app';
import { Language, useI18nContext } from '@/features/i18n';

export function GcdmseClearance({
  documentUrl,
}: {
  documentUrl: string;
}) {
  const { language } = useI18nContext();

  return (
    <Card>
      <div className="text-lg font-semibold text-center mb-2">
        Ground Cannabis Dust Allergy Screening
      </div>
      <h2 className="text-3xl font-semibold text-center text-green-600 mb-8">
        {{
          [Language.ENGLISH]: 'CLEARED',
          [Language.SPANISH]: 'SPANISH',
        }[language]}
      </h2>
      <p className="mb-4">
        {{
          [Language.ENGLISH]: `
            Your annual screening is now complete. No further action is
            required. If you experience any symptoms you think may be related to
            your work, please notify your supervisor immediately. If you have
            questions, please contact
          `,
          [Language.SPANISH]: `
            SPANISH
          `,
        }[language]}
        <a className="text-primary hover:underline" href="mailto:support@proximawork.com">support@proximawork.com</a>
        {{
          [Language.ENGLISH]: '.',
          [Language.SPANISH]: '.',
        }[language]}
      </p>
      <JobRoleSummary />
      <div className="border-t-2 border-gray-100 pt-4 mt-4">
        {{
          [Language.ENGLISH]: `
            A clearance document will be sent to the email address you provided
            on this form. Click below to download a printable copy of your
            clearance document now:
          `,
          [Language.SPANISH]: `
            SPANISH
          `,
        }[language]}
      </div>
      <div className="mt-4">
        <a href={documentUrl} rel="noreferrer" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-primary mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>
        </a>
      </div>
    </Card>
  );
}

import { RadioGroup } from '@headlessui/react';

import { useFormContext } from '../contexts';
import { InlineRadioFieldUiProps } from '../types';

import { ErrorMessage } from './ErrorMessage';

export function InlineRadioField({
  options,
  prompt,
  questionId,
}: InlineRadioFieldUiProps) {
  const { getError, getResponse, setResponse } = useFormContext();

  const error = getError(questionId);
  const value = getResponse(questionId);
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <div>
      <RadioGroup onChange={(option) => {
        // TODO | Check
        if (option === null) {
          throw new Error();
        }

        setResponse(questionId, option.value);
      }} value={selectedOption}>
        <>
          <RadioGroup.Label className={`
            ${error ? 'text-red-600 has-error' : 'text-gray-900'}
            block mb-2 font-medium leading-6
          `}>
            {prompt}
          </RadioGroup.Label>
          <div className="flex flex-row justify-start gap-3">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ active, checked }) => `
                  option-${option.value}
                  ${checked ? 'option-checked' : 'option-unchecked'}
                  cursor-pointer flex items-center justify-center rounded-md py-3 px-3 text-sm
                  font-medium uppercase
                  focus:outline-none
                  ${active ? 'ring-2 ring-primary ring-offset-2' : ''}
                  ${checked
                    ? `
                      bg-primary text-white 
                      hover:brightness-110
                    `
                    : `
                      ring-1 ring-inset ring-gray-300 bg-white text-gray-900 
                      hover:bg-gray-50
                    `
                  }
                `}
              >
                <RadioGroup.Label as="span">
                  {option.label}
                </RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </>
      </RadioGroup>
      {error && (
        <ErrorMessage error={error} />
      )}
    </div>
  );
}

export function FieldSubprompt({
  subprompt,
}: {
  subprompt: string;
}) {
  return (
    <div className="text-gray-500 text-sm">
      {subprompt}
    </div>
  );
}

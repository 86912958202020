import MarkdownToJsx from 'markdown-to-jsx';

export function Markdown({
  children,
}: {
  children: string;
}) {
  return (
    <MarkdownToJsx
      options={{
        overrides: {
          a: {
            props: {
              className: 'text-primary hover:underline',
            },
          },
        },
      }}
    >
      {children}
    </MarkdownToJsx>
  );
}

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';

import { Markdown } from '@/features/dynamic-form';

import { DropdownDirection } from '../constants';
import { useFormContext } from '../contexts';
import { SelectFieldUiProps, SelectOption } from '../types';

import { ErrorMessage } from './ErrorMessage';

export function SelectField({
  allowUnset = false,
  opens = DropdownDirection.RIGHT,
  options,
  prompt,
  questionId,
  subprompt,
}: SelectFieldUiProps) {
  const { getError, getResponse, setResponse } = useFormContext();

  const error = getError(questionId);
  const value = getResponse(questionId);
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <div className={`flex flex-col gap-y-1`}>
      <div className="flex flex-col">
        <Listbox
          onChange={(option) => {
            if (!allowUnset && option === null) {
              throw new Error();  // TODO | ShouldNotHappen
            }

            setResponse(questionId, option === null ? null : option.value);
          }}
          value={selectedOption}
        >
          {({ open }) => (
            <>
              <Listbox.Label className={`
                ${error !== undefined ? 'text-red-600 has-error': 'text-gray-900'}
                block mb-2 font-medium leading-6
              `}>
                <Markdown>
                  {prompt}
                </Markdown>
              </Listbox.Label>
              <div className="relative">
                <Listbox.Button
                  className={`
                    ${error !== undefined
                      ? 'text-red-900 ring-red-300 focus:ring-red-500'
                      : 'text-gray-900 ring-gray-300 focus:ring-primary'
                    }
                    relative w-full py-1.5 pl-3 pr-10 bg-white text-left rounded-md shadow-sm ring-1
                    ring-inset
                    focus:outline-none focus:ring-2 
                  `}
                >
                  <span className="block truncate">
                    {selectedOption !== null ? selectedOption.label : '\u00a0'}
                  </span>
                  <span className={`
                    absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none
                  `}>
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className={`
                    ${opens === DropdownDirection.RIGHT ? 'left-0' : 'right-0'}
                    absolute mt-1 py-1 bg-white min-w-60 max-h-36 z-10 overflow-auto rounded-md
                    text-base shadow-lg ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  `}>
                    {(allowUnset || value === null) && (
                      <SelectFieldOption key="_NULL" option={null} />
                    )}
                    {options.map((option) => (
                      <SelectFieldOption key={option.value} option={option} />
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {subprompt !== undefined && (
          <p className="mt-1 text-sm text-gray-500">
            {subprompt}
          </p>
        )}
      </div>
      {error !== undefined && (
        <ErrorMessage error={error} />
      )}
    </div>
  );
}

function SelectFieldOption<T extends number | string>({
  option,
}: {
  option: SelectOption<T> | null;
}) {
  return (
    <Listbox.Option as={Fragment} value={option}>
      {({ active, selected }) => (
        <li
          className={`
            ${active ? 'bg-primary text-white' : 'text-gray-900'}
            relative cursor-default select-none py-2 pl-3 pr-9
          `}
        >
          <span
            className={`
              ${selected ? 'font-medium' : 'font-normal'}
              block truncate
            `}
          >
            {option !== null ? option.label : '\u00a0'}
          </span>
          {selected && (
            <span
              className={`
                ${active ? 'text-white': 'text-primary'}
                absolute inset-y-0 right-0 flex items-center pr-4
              `}
            >
              <CheckIcon aria-hidden="true" className="h-5 w-5" />
            </span>
          )}
        </li>
      )}
    </Listbox.Option>
  );
}

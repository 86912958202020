import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { Language } from './constants';

export type I18nContextValue = {
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
};

export const i18nContext = createContext<I18nContextValue>({} as I18nContextValue);

export function useI18nContext() {
  return useContext(i18nContext);
}

export enum TemplateFunctionName {
  ALL = '!all',
  ANY = '!any',
  CONCAT = '!cat',
  CONTEXT = '!ctx',
  CONTEXT_EXISTS = '!ctxEx',
  DATE_PART = '!datePart',
  FIND = '!find',
  EQUALS = '!eq',
  GTE = '!gte',
  INCLUDES = '!inc',
  LOOP_ITEM = '!loopItem',
  MAP = '!map',
  NOT = '!not',
  RANGE = '!range',
  VARY = '!vary',
}

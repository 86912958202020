import Drawer from '@mui/material/Drawer';
import { ReactNode } from 'react';

import { LanguageSelector } from '@/features/i18n';

import { useAppContext } from '../contexts';
import { DrawerContent } from '../constants';

import { Attestation } from './Attestation';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsOfUse } from './TermsOfUse';

export function Layout({
  children,
  drawerContent,
}: {
  children: ReactNode;
  drawerContent: DrawerContent | null;
}) {
  const { appInitializer, setDrawerContent, submitting } = useAppContext();

  return (
    <>
      <nav className="fixed bg-white shadow w-full z-50">
        <div className="mx-auto max-w-3xl h-16 px-6 lg:px-8 flex flex-row items-center gap-x-4">
          <div className="grow">
            <img className="h-10 object-contain" src={appInitializer.branding.logoUrl} />
          </div>
          <LanguageSelector />
        </div>
      </nav>
      <div className="mx-auto max-w-3xl sm:px-6 lg:px-8 pt-28">
        {children}
      </div>
      <Drawer
        anchor="bottom"
        onClose={() => {
          if (!submitting) {
            setDrawerContent(null);
          }
        }}
        open={submitting || drawerContent !== null}
        sx={{
          ['& .MuiPaper-root']: {
            '--primary-color': appInitializer.branding.primaryColor,
            borderRadius: '10px 10px 0 0',
            maxHeight: '90vh',
          }
        }}
      >
        {drawerContent === DrawerContent.TOU
          ? <TermsOfUse />
          : drawerContent === DrawerContent.PRIVACY_POLICY
            ? <PrivacyPolicy />
            : drawerContent === DrawerContent.ATTESTATION
               ? <Attestation />
               : null
        }
      </Drawer>
    </>
  );
}

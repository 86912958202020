import { ReactNode, useState } from 'react'

import { Language } from '../constants'
import { i18nContext } from '../contexts'

export function I18nProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [language, setLanguage] = useState<Language>(Language.ENGLISH);

  return (
    <i18nContext.Provider value={{ language, setLanguage }}>
      {children}
    </i18nContext.Provider>
  );
}

import { Markdown } from '@/features/dynamic-form';

export function FormLabel({
  hasError,
  id,
  label,
}: {
  hasError: boolean;
  id?: string;
  label: string;
}) {
  return (
    <label htmlFor={id} className={`
      ${hasError ? 'text-red-600 has-error' : 'text-gray-900'}
      block font-medium leading-6
    `}>
      <Markdown>
        {label}
      </Markdown>
    </label>
  );
}

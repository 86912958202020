import { useId } from 'react';

export function TextInput({
  hasError,
  inputMode,
  maxLength,
  name,
  onChange,
  placeholder,
  rightAddOn,
  tabIndex,
  type = 'text',
  value,
}: {
  hasError: boolean;
  inputMode?: 'numeric';
  maxLength?: number;
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  rightAddOn?: string;
  tabIndex?: number;
  type?: 'email' | 'text';
  value: string;
}) {
  const id = useId();

  return (
    <div className="flex rounded-md shadow-sm">
      <input
        className={`
          ${hasError
            ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
            : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-primary'
          }
          ${rightAddOn
            ? 'min-w-0 flex-1 rounded-none rounded-l-md'
            : 'rounded-md'
          }
          block w-full border-0 py-1.5 ring-1 ring-inset
          focus:ring-2 focus:ring-inset
        `}
        id={id}
        inputMode={inputMode}
        maxLength={maxLength}
        name={name}
        onChange={(evt) => {
          onChange(evt.target.value);
        }}
        pattern={inputMode === 'numeric' ? '[0-9]*' : undefined}
        placeholder={placeholder}
        tabIndex={tabIndex}
        type={type}
        value={value}
      />
      {rightAddOn !== undefined && (
        <span className={`
          inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 
          text-gray-500
          sm:text-sm
        `}>
          {rightAddOn}
        </span>
      )}
    </div>
  );
}

import { Page } from '@/features/app';
import { Markdown } from '@/features/dynamic-form';
import { VerticalSpacer } from '@/features/app';

import { FormPageUiProps } from '../types';

import { FormModule } from './FormModule';
import { NextButton } from './NextButton';

export function FormPage({
  blurb,
  modules,
  nextButton,
  title,
}: FormPageUiProps) {
  return (
    <Page
      blurb={
        blurb !== undefined
        ? (
          <Markdown>
            {blurb}
          </Markdown>
        )
        : undefined
      }
      title={title}
    >
      <VerticalSpacer>
        {modules.map((moduleProps) => (
          <FormModule
            key={moduleProps.id}
            {...moduleProps}
          />
        ))}
        <NextButton {...nextButton} />
      </VerticalSpacer>
    </Page>
  );
}

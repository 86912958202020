export enum AppStage {
  PII = 'PII',
  PROHIBITED = 'PROHIBITED',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  RESULT = 'RESULT',
  START = 'START',
}

export enum DrawerContent {
  ATTESTATION = 'ATTESTATION',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TOU = 'TOU',
}

export enum RespiratorType {
  AIR_PURIFYING_DISPOSABLE = 'AIR_PURIFYING_DISPOSABLE',
  AIR_PURIFYING_REUSABLE_FULL_FACE = 'AIR_PURIFYING_REUSABLE_FULL_FACE',
  AIR_PURIFYING_REUSABLE_PARTIAL_FACE = 'AIR_PURIFYING_REUSABLE_PARTIAL_FACE',
  POWERED_AIR_PURIFYING_FULL_FACE = 'POWERED_AIR_PURIFYING_FULL_FACE',
  POWERED_AIR_PURIFYING_PARTIAL_FACE = 'POWERED_AIR_PURIFYING_PARTIAL_FACE',
  SUPPLIED_AIR_HOSE_FULL_FACE = 'SUPPLIED_AIR_HOSE_FULL_FACE',
  SUPPLIED_AIR_HOSE_PARTIAL_FACE = 'SUPPLIED_AIR_HOSE_PARTIAL_FACE',
  SUPPLIED_AIR_SCBA = 'SUPPLIED_AIR_SCBA',
}

export enum RespiratorUsageScenario {
  ESCAPE = 'ESCAPE',
  FIREFIGHTING = 'FIREFIGHTING',
  RESCUE = 'RESCUE',
  RESCUE_OVERSIGHT = 'RESCUE_OVERSIGHT',
  ROUTINE = 'ROUTINE',
  SITUATIONAL = 'SITUATIONAL',
}

export enum RespiratoryHazard {
  CHEMICALS = 'CHEMICALS',
  DUST = 'DUST',
  FUMES = 'FUMES',
  INFECTIOUS_AGENTS = 'INFECTIOUS_AGENTS',
  OXYGEN_DEFICIENT = 'OXYGEN_DEFICIENT',
  OXYGEN_ENRICHED = 'OXYGEN_ENRICHED',
  SENSITIZERS = 'SENSITIZERS',
  SMOKE = 'SMOKE',
  VAPORS = 'VAPORS',
}

export enum GenderIdentity {
  MAN = 'MAN',
  NONBINARY = 'NONBINARY',
  PREFER_NOT_TO_ANSWER = 'PREFER_NOT_TO_ANSWER',
  TRANSGENDER = 'TRANSGENDER',
  WOMAN = 'WOMAN',
}

export enum NameSuffix {
  II = 'II',
  III = 'III',
  IV = 'IV',
  JR = 'JR',
  SR = 'SR',
  V = 'V',
}

export enum Sex {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

import {
  FieldType,
  FormFieldUiProps,
  FormModuleUiProps,
  FormPageUiProps,
  isQuestionFormFieldUiProps,
} from '@/features/form';
import { DataType } from '@/types/data';

import { DynamicForm } from '../types';

export function expandDynamicForm(formProps: DynamicForm): DynamicForm {
  const newPageUiProps: FormPageUiProps[] = [];

  for (const pageUiProps of formProps.ui.pages) {
    const newModuleUiProps: FormModuleUiProps[] = [];

    for (const moduleUiProps of pageUiProps.modules) {
      const newFieldUiProps: FormFieldUiProps[] = [];

      for (const fieldUiProps of moduleUiProps.fields) {
        if (isQuestionFormFieldUiProps(fieldUiProps)) {
          switch(fieldUiProps.type) {
            case FieldType.HEIGHT_WEIGHT: {
              const heightQuestion = formProps.questions[fieldUiProps.questionId.height];
              const weightQuestion = formProps.questions[fieldUiProps.questionId.weight];

              if (heightQuestion === undefined || heightQuestion.type !== DataType.NUMBER
                  || weightQuestion === undefined || weightQuestion.type !== DataType.NUMBER) {
                throw new Error();  // TODO
              }

              // FUTURE | Don't override props set directly on the field
              newFieldUiProps.push({
                ...fieldUiProps,
                height: {
                  ...fieldUiProps.height,
                  max: heightQuestion.max,
                  min: heightQuestion.min,
                },
                weight: {
                  ...fieldUiProps.weight,
                  max: weightQuestion.max,
                  min: weightQuestion.min,
                },
              });

              break;
            }
            case FieldType.STACKED_CHECKBOX: {
              const question = formProps.questions[fieldUiProps.questionId];

              if (question === undefined || question.type !== DataType.ARRAY) {
                throw new Error();  // TODO
              }

              // FUTURE | Don't override props set directly on the field
              newFieldUiProps.push({
                ...fieldUiProps,
                notaValue: question.notaValue,
              });

              break;
            }
            default: {
              newFieldUiProps.push(fieldUiProps);
              break;
            }
          }
        } else {
          newFieldUiProps.push(fieldUiProps);
        }
      }

      newModuleUiProps.push({
        ...moduleUiProps,
        fields: newFieldUiProps,
      });
    }

    newPageUiProps.push({
      ...pageUiProps,
      modules: newModuleUiProps,
    });
  }

  return {
    ...formProps,
    ui: {
      ...formProps.ui,
      pages: newPageUiProps,
    },
  };
}

import { useFormContext } from '../contexts';
import { TextareaFieldUiProps } from '../types';

import { FieldWrapper } from './FieldWrapper';

export function TextareaField({
  id,
  maxLength,
  placeholder,
  prompt,
  questionId,
  rows,
  subprompt,
}: TextareaFieldUiProps) {
  const { getError, getResponse, setResponse } = useFormContext();

  const error = getError(questionId);
  const value = getResponse(questionId);

  if (typeof value !== 'string') {
    throw new Error();  // TODO
  }

  return (
    <FieldWrapper error={error} id={id} prompt={prompt}>
      <textarea
        className={`
          ${error !== undefined
            ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
            : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-primary'
          }
          block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset resize-none
          focus:ring-2 focus:ring-inset
        `}
        maxLength={maxLength}
        name={id}
        onChange={(evt) => {
          setResponse(questionId, evt.target.value);
        }}
        placeholder={placeholder}
        rows={rows}
        value={value}
      />
      {subprompt !== undefined && (
        <p className="mt-1 text-sm text-gray-500">
          {subprompt}
        </p>
      )}
    </FieldWrapper>
  );
}

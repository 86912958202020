import { ReactNode } from 'react';

export function Card({
  children,
  title,
}: {
  children: ReactNode;
  title?: string;
}) {
  return (
    <div className="flex flex-col gap-y-2">
      {title !== undefined && (
        <h2 className="text-lg font-display font-medium px-4 sm:px-0">
          {title}
        </h2>
      )}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          {children}
        </div>
      </div>
    </div>
  );
}

import { JsonObject, JsonValue } from '@/types/json';
import { isJsonArray, isJsonObject } from '@/utils/json';

import { TemplateFunctionName } from '../constants';
import { RenderContext } from '../types';

import {
  all,
  any,
  cat,
  ctx,
  ctxEx,
  datePart,
  eq,
  find,
  gte,
  inc,
  isAllTemplateFunction,
  isAnyTemplateFunction,
  isConcatTemplateFunction,
  isContextExistsTemplateFunction,
  isContextTemplateFunction,
  isDatePartTemplateFunction,
  isEqualsTemplateFunction,
  isFindTemplateFunction,
  isGteTemplateFunction,
  isIncludesTemplateFunction,
  isLoopItemTemplateFunction,
  isMapTemplateFunction,
  isNotTemplateFunction,
  isRangeTemplateFunction,
  isTemplateFunction,
  isVaryTemplateFunction,
  loopItem,
  map,
  not,
  range,
  vary,
} from './functions';
import { UnsupportedTemplateFunctionError } from '../errors';

export function render({
  context,
  template,
}: {
  context: JsonObject;
  template: JsonValue;
}): JsonValue {
  const value = renderInternal({
    context: {
      loopItems: {},
      userContext: {
        data: context,
      },
    },
    template,
  });

  return value;
}

export function renderInternal({
  context,
  template,
}: {
  context: RenderContext;
  template: JsonValue;
}): JsonValue {
  if (isJsonObject(template)) {
    if (isTemplateFunction(template)) {
      if (isAllTemplateFunction(template)) {
        return all({
          args: template[TemplateFunctionName.ALL],
          context,
        });
      } else if (isAnyTemplateFunction(template)) {
        return any({
          args: template[TemplateFunctionName.ANY],
          context,
        });
      } else if (isConcatTemplateFunction(template)) {
        return cat({
          args: template[TemplateFunctionName.CONCAT],
          context,
        });
      } else if (isContextTemplateFunction(template)) {
        return ctx({
          args: template[TemplateFunctionName.CONTEXT],
          context,
        });
      } else if (isContextExistsTemplateFunction(template)) {
        return ctxEx({
          args: template[TemplateFunctionName.CONTEXT_EXISTS],
          context,
        });
      } else if (isDatePartTemplateFunction(template)) {
        return datePart({
          args: template[TemplateFunctionName.DATE_PART],
          context,
        });
      } else if (isEqualsTemplateFunction(template)) {
        return eq({
          args: template[TemplateFunctionName.EQUALS],
          context,
        });
      } else if (isFindTemplateFunction(template)) {
        return find({
          args: template[TemplateFunctionName.FIND],
          context,
        });
      } else if (isGteTemplateFunction(template)) {
        return gte({
          args: template[TemplateFunctionName.GTE],
          context,
        });
      } else if (isIncludesTemplateFunction(template)) {
        return inc({
          args: template[TemplateFunctionName.INCLUDES],
          context,
        });
      } else if (isLoopItemTemplateFunction(template)) {
        return loopItem({
          args: template[TemplateFunctionName.LOOP_ITEM],
          context,
        });
      } else if (isMapTemplateFunction(template)) {
        return map({
          args: template[TemplateFunctionName.MAP],
          context,
        });
      } else if (isNotTemplateFunction(template)) {
        return not({
          args: template[TemplateFunctionName.NOT],
          context,
        });
      } else if (isRangeTemplateFunction(template)) {
        return range({
          args: template[TemplateFunctionName.RANGE],
          context,
        });
      } else if (isVaryTemplateFunction(template)) {
        return vary({
          args: template[TemplateFunctionName.VARY],
          context,
        });
      } else {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion --
         * single key is guaranteed by isTemplateFunction above
         */
        throw new UnsupportedTemplateFunctionError(Object.keys(template)[0]!.substring(1));
      }
    }

    return Object.entries(template).reduce(
      (acc, [key, innerTemplate]) => ({
        ...acc,
        [key]: renderInternal({
          context,
          template: innerTemplate,
        }),
      }),
      {},
    );
  } else if (isJsonArray(template)) {
    return template.map(
      (innerTemplate) => renderInternal({
        context,
        template: innerTemplate,
      }),
    );
  } else {
    return template;
  }
}

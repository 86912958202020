import { yearFromIsoDate } from '@/lib/time';
import { isValidDate } from '@/lib/validation';
import { JsonArray, JsonObject, JsonValue } from '@/types/json';
import { isJsonArray, isJsonObject } from '@/utils/json';

import { TemplateFunctionName } from '../constants';
import { TemplateContextNotFoundError } from '../errors';
import {
  AllTemplateFunction,
  AnyTemplateFunction,
  ConcatTemplateFunction,
  ContextExistsTemplateFunction,
  ContextTemplateFunction,
  DatePartTemplateFunction,
  EqualsTemplateFunction,
  FindTemplateFunction,
  GteTemplateFunction,
  IncludesTemplateFunction,
  LoopItemTemplateFunction,
  MapTemplateFunction,
  NotTemplateFunction,
  RangeTemplateFunction,
  RenderContext,
  TemplateFunction,
  VaryTemplateFunction,
} from '../types';

import { renderInternal } from './render';

export function all({
  args,
  context,
}: {
  args: AllTemplateFunction[TemplateFunctionName.ALL];
  context: RenderContext;
}): boolean {
  for (const conditionTemplate of args.conditions) {
    const condition = renderInternal({
      context,
      template: conditionTemplate,
    });

    if (typeof condition !== 'boolean') {
      throw new Error();  // TODO | Wrong type
    }

    if (!condition) {
      return false;
    }
  }

  return true;
}

export function any({
  args,
  context,
}: {
  args: AnyTemplateFunction[TemplateFunctionName.ANY];
  context: RenderContext;
}): boolean {
  for (const conditionTemplate of args.conditions) {
    const condition = renderInternal({
      context,
      template: conditionTemplate,
    });

    if (typeof condition !== 'boolean') {
      throw new Error();  // TODO | Wrong type
    }

    if (condition) {
      return true;
    }
  }

  return false;
}

export function cat({
  args,
  context,
}: {
  args: ConcatTemplateFunction[TemplateFunctionName.CONCAT];
  context: RenderContext;
}): string {
  const tokens = renderInternal({
    context,
    template: args.tokens,
  });

  if (!isJsonArray(tokens) || tokens.some((token) => typeof token !== 'string')) {
    throw new Error();  // TODO
  }

  return tokens.join('');
}

export function ctx({
  args,
  context,
}: {
  args: ContextTemplateFunction[TemplateFunctionName.CONTEXT];
  context: RenderContext;
}): JsonValue {
  const path = renderInternal({
    context,
    template: args.path,
  });

  if (!isJsonArray(path)) {
    throw new Error();  // TODO | Wrong type
  }

  let value: JsonValue | undefined = context.userContext.data;
  const currentPath: string[] = [];

  for (const segment of path) {
    if (!isJsonObject(value) || typeof segment !== 'string') {
      throw new Error();  // TODO | Wrong types
    }

    currentPath.push(segment);
    value = value[segment];

    if (value === undefined) {
      throw new TemplateContextNotFoundError(currentPath);
    }
  }

  return value;
}

export function ctxEx({
  args,
  context,
}: {
  args: ContextExistsTemplateFunction[TemplateFunctionName.CONTEXT_EXISTS];
  context: RenderContext;
}): boolean {
  const path = renderInternal({
    context,
    template: args.path,
  });

  if (!isJsonArray(path)) {
    throw new Error();  // TODO | Wrong type
  }

  let value: JsonValue | undefined = context.userContext.data;

  for (const segment of path) {
    if (!isJsonObject(value) || typeof segment !== 'string') {
      throw new Error();  // TODO | Wrong types
    }

    value = value[segment];

    if (value === undefined) {
      return false;
    }
  }

  return true;
}

export function datePart({
  args,
  context,
}: {
  args: DatePartTemplateFunction[TemplateFunctionName.DATE_PART];
  context: RenderContext;
}): number {
  const date = renderInternal({
    context,
    template: args.date,
  });

  const part = renderInternal({
    context,
    template: args.part,
  });

  if (typeof date !== 'string' || !isValidDate({ date }) || typeof part !== 'string') {
    throw new Error();  // TODO | Wrong type
  }

  let value: number;

  switch (part) {
    case 'YEAR': {
      value = yearFromIsoDate(date);
      break;
    }
    default: {
      throw new Error();  // TODO | Unsupported part
    }
  }

  return value;
}

export function eq({
  args,
  context,
}: {
  args: EqualsTemplateFunction[TemplateFunctionName.EQUALS];
  context: RenderContext;
}): boolean {
  const lhs = renderInternal({
    context,
    template: args.lhs,
  });

  const rhs = renderInternal({
    context,
    template: args.rhs,
  });

  return lhs === rhs;
}

export function find({
  args,
  context,
}: {
  args: FindTemplateFunction[TemplateFunctionName.FIND];
  context: RenderContext;
}): JsonValue {
  const map = renderInternal({
    context,
    template: args.map,
  });

  if (!isJsonObject(map)) {
    throw new Error();  // TODO | Wrong type 
  }

  const path = renderInternal({
    context,
    template: args.path,
  });

  if (!isJsonArray(path)) {
    throw new Error();  // TODO | Wrong type
  }

  let value: JsonValue | undefined = map;

  for (const segment of path) {
    if (!isJsonObject(value) || typeof segment !== 'string') {
      throw new Error();  // TODO | Wrong types
    }

    value = value[segment];

    if (value === undefined) {
      throw new Error();  // TODO
    }
  }

  return value;
}

export function gte({
  args,
  context,
}: {
  args: GteTemplateFunction[TemplateFunctionName.GTE];
  context: RenderContext;
}): boolean {
  const lhs = renderInternal({
    context,
    template: args.lhs,
  });

  const rhs = renderInternal({
    context,
    template: args.rhs,
  });

  if (typeof lhs !== 'number' || typeof rhs !== 'number') {
    throw new Error();  // TODO | Wrong type
  }

  return lhs >= rhs;
}

export function inc({
  args,
  context,
}: {
  args: IncludesTemplateFunction[TemplateFunctionName.INCLUDES];
  context: RenderContext;
}): boolean {
  const collection = renderInternal({
    context,
    template: args.collection,
  });

  const item = renderInternal({
    context,
    template: args.item,
  });

  if (!isJsonArray(collection) || (typeof item !== 'number' && typeof item !== 'string')) {
    throw new Error();  // TODO
  }

  return collection.includes(item);
}

export function isAllTemplateFunction(
  func: TemplateFunction,
): func is AllTemplateFunction {
  return TemplateFunctionName.ALL in func;
}

export function isAnyTemplateFunction(
  func: TemplateFunction,
): func is AnyTemplateFunction {
  return TemplateFunctionName.ANY in func;
}

export function isConcatTemplateFunction(
  func: TemplateFunction,
): func is ConcatTemplateFunction {
  return TemplateFunctionName.CONCAT in func;
}

export function isContextExistsTemplateFunction(
  func: TemplateFunction,
): func is ContextExistsTemplateFunction {
  return TemplateFunctionName.CONTEXT_EXISTS in func;
}

export function isContextTemplateFunction(
  func: TemplateFunction,
): func is ContextTemplateFunction {
  return TemplateFunctionName.CONTEXT in func;
}

export function isDatePartTemplateFunction(
  func: TemplateFunction,
): func is DatePartTemplateFunction {
  return TemplateFunctionName.DATE_PART in func;
}

export function isEqualsTemplateFunction(
  func: TemplateFunction,
): func is EqualsTemplateFunction {
  return TemplateFunctionName.EQUALS in func;
}

export function isFindTemplateFunction(
  func: TemplateFunction,
): func is FindTemplateFunction {
  return TemplateFunctionName.FIND in func;
}

export function isGteTemplateFunction(
  func: TemplateFunction,
): func is GteTemplateFunction {
  return TemplateFunctionName.GTE in func;
}

export function isIncludesTemplateFunction(
  func: TemplateFunction,
): func is IncludesTemplateFunction {
  return TemplateFunctionName.INCLUDES in func;
}

export function isLoopItemTemplateFunction(
  func: TemplateFunction,
): func is LoopItemTemplateFunction {
  return TemplateFunctionName.LOOP_ITEM in func;
}

export function isMapTemplateFunction(
  func: TemplateFunction,
): func is MapTemplateFunction {
  return TemplateFunctionName.MAP in func;
}

export function isNotTemplateFunction(
  func: TemplateFunction,
): func is NotTemplateFunction {
  return TemplateFunctionName.NOT in func;
}

export function isRangeTemplateFunction(
  func: TemplateFunction,
): func is RangeTemplateFunction {
  return TemplateFunctionName.RANGE in func;
}

export function isTemplateFunction(obj: JsonObject): obj is TemplateFunction {
  const keys = Object.keys(obj);

  if (keys.length !== 1) {
    return false;
  }

  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion --
   * obj is guaranteed to have one and only one key by the logic above
   */
  return keys[0]!.startsWith('!');
}

export function isVaryTemplateFunction(
  func: TemplateFunction,
): func is VaryTemplateFunction {
  return TemplateFunctionName.VARY in func;
}

export function loopItem({
  args,
  context,
}: {
  args: LoopItemTemplateFunction[TemplateFunctionName.LOOP_ITEM];
  context: RenderContext;
}): JsonValue {
  const loopName = renderInternal({
    context,
    template: args.loopName,
  });

  if (typeof loopName !== 'string') {
    throw new Error();  // TODO | Wrong type
  }

  const value = context.loopItems[loopName];

  if (value === undefined) {
    throw new Error();  // TODO
  }

  return value;
}

export function map({
  args,
  context,
}: {
  args: MapTemplateFunction[TemplateFunctionName.MAP];
  context: RenderContext;
}): JsonArray {
  const collection = renderInternal({
    context,
    template: args.collection,
  });

  const loopName = renderInternal({
    context,
    template: args.loopName,
  });

  if (!isJsonArray(collection) || typeof loopName !== 'string') {
    throw new Error();  // TODO | Wrong type
  }

  const values: JsonValue[] = [];

  for (const item of collection) {
    const value = renderInternal({
      context: {
        ...context,
        loopItems: {
          ...context.loopItems,
          [loopName]: item,
        },
      },
      template: args.callback,
    });

    values.push(value);
  }

  return values;
}

export function not({
  args,
  context,
}: {
  args: NotTemplateFunction[TemplateFunctionName.NOT];
  context: RenderContext;
}): boolean {
  const value = renderInternal({
    context,
    template: args.value,
  });

  if (typeof value !== 'boolean') {
    throw new Error();  // TODO | Wrong type
  }

  return !value;
}

export function range({
  args,
  context,
}: {
  args: RangeTemplateFunction[TemplateFunctionName.RANGE];
  context: RenderContext;
}): number[] {
  const from = renderInternal({
    context,
    template: args.from,
  });

  const to = renderInternal({
    context,
    template: args.to,
  });

  if (typeof from !== 'number' || !Number.isInteger(from)
      || typeof to !== 'number' || !Number.isInteger(to)) {
    throw new Error();  // TODO
  }

  const value: number[] = [];

  const reverse = from > to;
  const start = reverse ? to : from;
  const end = reverse ? from : to;

  for (let i = start; i <= end; i++) {
    if (reverse) {
      value.unshift(i);
    } else {
      value.push(i);
    }
  }

  return value;
}

export function vary({
  args,
  context,
}: {
  args: VaryTemplateFunction[TemplateFunctionName.VARY];
  context: RenderContext;
}): JsonValue {
  const condition = renderInternal({
    context,
    template: args.condition,
  });

  if (typeof condition !== 'boolean') {
    throw new Error();
  }

  let value: JsonValue;

  if (condition) {
    value = renderInternal({
      context,
      template: args.whenTrue,
    });
  } else {
    value = renderInternal({
      context,
      template: args.whenFalse,
    });
  }

  return value;
}

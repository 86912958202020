import { isJsonArray } from '@/utils/json';

import { useFormContext } from '../contexts';
import { StackedCheckboxFieldUiProps } from '../types';

import { FieldWrapper } from './FieldWrapper';

export function StackedCheckboxField({
  notaValue,
  options,
  prompt,
  questionId,
}: StackedCheckboxFieldUiProps) {
  const { getError, getResponse, setResponse } = useFormContext();

  const error = getError(questionId);
  const value = getResponse(questionId) ?? [];

  if (!isJsonArray(value)) {
    throw new Error();  // TODO
  }

  return (
    <fieldset>
      <FieldWrapper error={error} prompt={prompt}>
        <div className="space-y-4">
          {options.map((option) => {
            const checked = value.includes(option.value);

            return (
              <div
                className={`
                  ${checked ? 'border-primary ring-2 ring-primary' : 'border-gray-300'}
                  relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm
                  focus:outline-none sm:flex sm:justify-between
                `}
                key={option.value}
                onClick={() => {
                  if (option.value === notaValue) {
                    if (value.length === 1 && value[0] === notaValue) {
                      setResponse(questionId, []);
                    } else {
                      setResponse(questionId, [option.value]);
                    }
                  } else if (!checked) {
                    setResponse(
                      questionId,
                      [...value, option.value].filter((val) => val !== notaValue),
                    );
                  } else {
                    setResponse(
                      questionId,
                      value.filter((val) => val !== option.value),
                    );
                  }
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </FieldWrapper>
    </fieldset>
  );
}

import { Language, useI18nContext } from '@/features/i18n';

export function SubmitError() {
  const { language } = useI18nContext();

  return (
    <div className="mb-2 bg-red-100 rounded-md border-red-500 border text-red-500 p-2 text-sm">
      {{
        [Language.ENGLISH]: 'An error has occurred while trying to submit your form. Please double check your internet connection and try again.',
        [Language.SPANISH]: 'Se ha producido un error al intentar enviar su formulario. Verifique nuevamente su conexión a Internet e inténtelo nuevamente.',
      }[language]}
    </div>
  );
}

import { RadioGroup } from '@headlessui/react';

import { useFormContext } from '../contexts';
import { StackedRadioFieldUiProps } from '../types';

import { ErrorMessage } from './ErrorMessage';

export function StackedRadioField({
  options,
  prompt,
  questionId,
}: StackedRadioFieldUiProps) {
  const { getError, getResponse, setResponse } = useFormContext();

  const error = getError(questionId);
  const value = getResponse(questionId);
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <div>
      <RadioGroup onChange={(option) => {
        // TODO | Check
        if (option === null) {
          throw new Error();
        }

        setResponse(questionId, option.value);
      }} value={selectedOption}>
        <>
          <RadioGroup.Label className={`
            ${error ? 'text-red-600 has-error' : 'text-gray-900'}
            block mb-2 font-medium leading-6
          `}>
            {prompt}
          </RadioGroup.Label>
          <div className="space-y-4">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) => `
                  ${checked ? 'border-primary ring-2 ring-primary' : 'border-gray-300'}
                  relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm
                  focus:outline-none sm:flex sm:justify-between
                `}
              >
                <RadioGroup.Label as="span">
                  {option.label}
                </RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </>
      </RadioGroup>
      {error && (
        <ErrorMessage error={error} />
      )}
    </div>
  );
}

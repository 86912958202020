import { DropdownDirection, LegacySelectField } from '@/features/form';

import { Language } from '../constants';
import { useI18nContext } from '../contexts';

export function LanguageSelector() {
  const { language, setLanguage } = useI18nContext();

  return (
    <LegacySelectField<Language>
      onChange={(newLanguage) => {
        setLanguage(newLanguage);
      }}
      opens={DropdownDirection.LEFT}
      options={[
        {
          label: 'English',
          value: Language.ENGLISH,
        },
        {
          label: 'Español',
          value: Language.SPANISH,
        },
      ]}
      value={language}
    />
  );
}

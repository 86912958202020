import { Card } from '@/features/app';

import { FormModuleUiProps } from '../types';
import { FieldType } from '../constants';

import { BloodPressureField } from './BloodPressureField';
import { HeightWeightField } from './HeightWeightField';
import { InfoField } from './InfoField';
import { InlineRadioField } from './InlineRadioField';
import { SelectField } from './SelectField';
import { StackedCheckboxField } from './StackedCheckboxField';
import { StackedRadioField } from './StackedRadioField';
import { TextareaField } from './TextareaField';

export function FormModule({
  fields,
  title,
}: FormModuleUiProps) {
  return (
    <Card title={title}>
      <div className="flex flex-col gap-y-8">
        {fields.map((fieldProps) => {
          if (fieldProps.type === FieldType.BLOOD_PRESSURE) {
            return <BloodPressureField key={fieldProps.id} {...fieldProps} />;
          } else if (fieldProps.type === FieldType.HEIGHT_WEIGHT) {
            return <HeightWeightField key={fieldProps.id} {...fieldProps} />;
          } else if (fieldProps.type === FieldType.INFO) {
            return <InfoField key={fieldProps.id} {...fieldProps} />;
          } else if (fieldProps.type === FieldType.INLINE_RADIO) {
            return <InlineRadioField key={fieldProps.id} {...fieldProps} />;
          } else if (fieldProps.type === FieldType.SELECT) {
            return <SelectField key={fieldProps.id} {...fieldProps} />;
          } else if (fieldProps.type === FieldType.STACKED_CHECKBOX) {
            return <StackedCheckboxField key={fieldProps.id} {...fieldProps} />;
          } else if (fieldProps.type === FieldType.STACKED_RADIO) {
            return <StackedRadioField key={fieldProps.id} {...fieldProps} />;
          } else {  // fieldProps.type === FieldType.TEXTAREA
            return <TextareaField key={fieldProps.id} {...fieldProps} />;
          }
        })}
      </div>
    </Card>
  );
}

import { ReactNode } from 'react';

export function PageBlurb({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="mb-8 px-4 sm:px-0">
      {children}
    </div>
  );
}
